import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from "@reduxjs/toolkit/query";
import {backendApi} from "./services/backend/api";
import userReducer from "./shared/authentication/userSlice";
import accessTokenReducer from "./shared/authentication/accessTokenSlice";
import activeCompanyReducer from "./shared/active-company/active-company-slice";
import {msgraphApi} from "./services/msgraph/msgraph";

export const store = configureStore({
    reducer: {
        [backendApi.reducerPath]: backendApi.reducer,
        [msgraphApi.reducerPath]: msgraphApi.reducer,
        user: userReducer,
        accessToken: accessTokenReducer,
        activeCompany: activeCompanyReducer,
    },

    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(backendApi.middleware)
        .concat(msgraphApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)
