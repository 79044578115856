import {ConsumptionIcon, ReceiptIcon, TransferIcon} from "../../shared/components/icons";
import {Company} from "./company";
import {Store} from "./store";
import {CostCenter} from "./cost-center";
import {Product} from "./product";

export interface ShowStoreInventoryRequest {
    storeId: string
}

export interface ShowProductInventoryRequest {
    productId: string
}

export interface ShowLedgerRequest {
    storeId: string
    search: string
    from: string
    to: string
    page: number
    pageSize: number
}

export interface ShowCostCenterConsumptionRequest {
    costCenterId: string
    from: string
    to: string
}

export interface Ledger {
    store: Store
    entries: LedgerEntry[]
}

export interface LedgerEntry {
    entryNumber: number
    productId: string
    productName: string
    productManufacturerName: string
    count: number
    measure: string
    userId: string
    comment: string
    createdAt: string
    triggerType: string
    triggerId: string
}

export function IconForTrigger(props: { triggerType: string, className: string }) {
    switch (props.triggerType) {
        case "transfers":
            return <TransferIcon/>
        case "receipts":
            return <ReceiptIcon/>
        case "consumptions":
            return <ConsumptionIcon/>
        default:
            return <div>{props.triggerType}</div>
    }
}

export interface EntriesPage {
    page: number
    pageSize: number
    total: number
    totalPages: number
    entries: Entry[]
}

export interface Entry {
    entryOrder: string
    productId: string
    productName: string
    productManufacturerName: string
    count: number
    measure: string
    userId: string
    comment: string
    createdAt: string
    triggerType: string
    triggerId: number
}

export interface CostCenterConsumption {
    costCenter: CostCenter
    from: string
    to: string
    products: CostCenterConsumptionItem[]
}

export interface CostCenterConsumptionItem {
    productId: string
    product: Product
    count: number
    measure: string
}

export interface CompanyInventory {
    company: Company
    stores: StoreInventory[]
    products: ProductInventory[]
}

export interface ProductInventory {
    product: Product
    inventory: ProductInventoryItem[]
}

export interface ProductInventoryItem {
    storeId: string
    storeName: string
    count: number
    measure: string
}

export interface StoreInventory {
    store: Store
    inventory: StoreInventoryItem[]
}

export interface StoreInventoryItem {
    productId: string
    productName: string
    productManufacturerName: string
    count: number
    measure: string
}
