import {Outlet, useParams} from "react-router-dom";
import {useShowProductQuery} from "../../../services/backend/api";

export default function ProductRoute() {
    const {productId} = useParams()
    const {data: product, isSuccess} = useShowProductQuery({productId: productId ?? ""})
    if (!isSuccess) {
        return <div></div>
    }

    return (<>
        <h2 className="text-2xl font-bold dark:text-white">{product.name}</h2>
        <div className="pt-3">
            <Outlet/>
        </div>
    </>)
}