import {ChangeEvent, PropsWithChildren, ReactNode} from "react";

export function TableCard(props: PropsWithChildren) {
    return (
        <div
            className="p-4 bg-white border border-gray-200 rounded-lg shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
            <form className="space-y-6" action="#">
                {props.children}
            </form>
        </div>
    )
}

export function Table(props: { children: ReactNode, shadow?: boolean }) {
    let className="overflow-x-auto relative sm:rounded-lg"
    if (props.shadow != undefined && props.shadow) {
        className += " shadow-md"
    }
    return (
        <div className={className}>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                {props.children}
            </table>
        </div>
    )
}

export function TableHead(props: PropsWithChildren) {
    return (
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            {props.children}
        </thead>
    )
}

export function TableHeadRow(props: PropsWithChildren) {
    return (
        <tr>
            {props.children}
        </tr>
    )
}

export function TableHeadCell(props: PropsWithChildren) {
    return <th scope="col" className="py-3 px-6">{props.children}</th>
}

export function TableBody(props: PropsWithChildren) {
    return <tbody>{props.children}</tbody>
}

export function TableBodyRow(props: PropsWithChildren) {
    return (
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            {props.children}
        </tr>
    )
}

export function TableBodyRowHeadCell(props: PropsWithChildren) {
    return (
        <th scope="row" className="py-4 px-6 font-medium whitespace-nowrap text-gray-900 dark:text-white">
            {props.children}
        </th>
    )
}

export function TableBodyCell(props: {children: ReactNode, colSpan?: number, width?: string, textRight?: boolean}) {
    let className = "py-4 px-6 "
    if (props.width) {
        className += props.width
    }
    if (props.textRight != undefined && props.textRight) {
        className += " text-right"
    }
    return (
        <td className={className} colSpan={props.colSpan ?? undefined}>
            {props.children}
        </td>
    )
}

export function TableCellButton(props: {text: string, onClick: () => void, disabled?:boolean}) {
    if (props.disabled != undefined && props.disabled) {
        return  <button className="font-medium text-red-600 dark:text-red-500 hover:underline" disabled>
            {props.text}
        </button>
    }
    return (
        <button
            onClick={props.onClick}
            className="font-medium text-red-600 dark:text-red-500 hover:underline">
            {props.text}
        </button>
    )
}

export function TableActions(props: { children: ReactNode, justify?: string }) {
    let justify = "between"
    if (props.justify != undefined) {
        justify = props.justify
    }
    return (
        <div className={`pb-4 flex items-center justify-${justify} bg-white dark:bg-gray-900`}>
            {props.children}
        </div>
    )
}

export function TableActionSearch(props: {value: string, onChange: (e: ChangeEvent<HTMLInputElement>) => void, placeholder: string, loading?: boolean}) {
    return (<>
        <label htmlFor="table-search" className="sr-only">Search</label>
        <div className="relative">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                {
                    (props.loading != undefined && props.loading)
                        ? <TableActionSearchSpinner/>
                        : <TableActionSearchIcon/>
                }
            </div>
            <input type="text" id="table-search"
                   value={props.value}
                   onChange={props.onChange}
                   className="block p-2 pl-10 w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                   placeholder={props.placeholder}/>
        </div>
    </>)
}

function TableActionSearchSpinner() {
    return (
        <svg aria-hidden="true" role="status"
             className="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101"
             fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"/>
            <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="#1C64F2"/>
        </svg>
    )
}

function TableActionSearchIcon() {
    return (
        <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor"
             viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"></path>
        </svg>
    )
}

export function TableActionButton(props: {onClick: () => void, text: string}) {
    return (<div>
        <button type="button"
                onClick={props.onClick}
                className="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
        >
            {props.text}
        </button>
    </div>)
}

export function TableHeading(props: { text: string }) {
    return <h5 className="text-xl font-medium text-gray-900 dark:text-white">{props.text}</h5>
}

export interface TablePaginationData {
    page: number
    pageSize: number
    pageItemCount: number
    totalItemCount: number
    totalPageCount: number
}

export function TablePagination(props: {pageData: TablePaginationData, next: () => void, previous: () => void, selectPage: (page: number) => void}) {
    let from = props.pageData.pageItemCount == 0 ? 0 : props.pageData.page * props.pageData.pageSize - props.pageData.pageSize + 1
    let to = props.pageData.pageItemCount < props.pageData.pageSize
        ? props.pageData.page * props.pageData.pageSize - props.pageData.pageSize + props.pageData.pageItemCount
        : (props.pageData.page * props.pageData.pageSize)

    let pageLinks = []
    for (let i = 1; i <= props.pageData.totalPageCount; i++) {
        pageLinks.push(
            <li key={i}>
                <button
                    onClick={() => props.selectPage(i)}
                    className={ props.pageData.page == i
                        ? "z-10 py-2 px-3 leading-tight text-blue-600 bg-blue-50 border border-blue-300 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                        : "py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"}
                >{i}</button>
            </li>
        )
    }

    return (
        <nav className="flex justify-between items-center pt-4" aria-label="Table navigation">
             <span className="text-sm font-normal text-gray-500 dark:text-gray-400">Showing <span
                 className="font-semibold text-gray-900 dark:text-white">{from + "-" + to}</span> of <span
                 className="font-semibold text-gray-900 dark:text-white">{props.pageData.totalItemCount}</span></span>
            <ul className="inline-flex items-center -space-x-px">
                <li>
                    <button
                        onClick={() => props.previous()}
                        className="block py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                        <span className="sr-only">Previous</span>
                        <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                  clipRule="evenodd"></path>
                        </svg>
                    </button>
                </li>
                {pageLinks}
                <li>
                    <button
                        onClick={() => props.next()}
                        className="block py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                        <span className="sr-only">Next</span>
                        <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                  clipRule="evenodd"></path>
                        </svg>
                    </button>
                </li>
            </ul>
        </nav>
    )
}