export function t(string: string) {
    return translations[string] ?? string;
}

const translations: Record<string, string> = {
    // InputFieldNames, will be used for error messages below
    "productNumber": "Produkt Nummer",
    "manufacturerName": "Hersteller Name",
    "measure": "Maßeinheit",
    // Random
    "Connection refused": "Verbindung fehlgeschlagen",
    // Measures
    "pieces": "Stück",
    "meter": "Meter",
    "milligram": "Milligram",
    "gram": "Gram",
    "kilogram": "Kilogram",
    "milliliter": "Milliliter",
    "liter": "Liter",
    "roll": "Rolle",
    "pair": "Paar",
    "bottle": "Flasche",
    "canister": "Kanister",
    "packaging unit": "Verpackungseinheit",
    // Stores
    "vehicle": "Fahrzeug",
    "warehouse": "Niederlassung",
    // Triggers
    "receipts": "Annahme",
    "transfers": "Transfer",
    "consumptions": "Verbrauch",
    // Entities
    "Company": "Unternehmen",
};

export function tFieldError(code: number, fieldName: string): string {
    fieldName = t(fieldName)
    let messages = new Map([
        [1000, `${fieldName} ist ungültig.`],
        [1001, `${fieldName} muss angegeben werden.`],
        [1002, `${fieldName} bereits vergeben.`],
        [1003, `${fieldName} existiert nicht.`],
        [1004, `${fieldName} muss eine gültige UUID sein.`],
        [1005, `${fieldName} muss im Format RFC3339 angegeben sein.`],
        [1006, `Ungültige Maßeinheit`],
    ])

    let message = messages.get(code)
    if (!message) {
        message = t("Unbekannter Fehler")
    }
    return message
}

export function tError(code: number): string {
    let message = errorCodeMessages.get(code)
    if (!message) {
        message = t("Unbekannter Fehler")
    }
    return message
}

const errorCodeMessages: Map<number, string> = new Map([
    // InputValidation
    [1000, "Ungültige Eingabe."],
    [1001, "Das Feld ist erforderlich."],
    [1002, "Der Wert wurde bereits vergeben."],
    [1003, "Existiert nicht."],
    [1004, "Muss eine gültige UUID sein."],
    [1005, "Muss im Format RFC3339 angegeben sein."],
    [1006, "Ungültige Maßeinheit."],
    // DomainRule
    [2001, "Der Herstellername kann bei einem bestätigten Produkt nicht geändert werden."],
    [2002, "Maßeinheit des Produktes darf nicht geändert werden."],
    [2003, "Ein bestätigtes Produkt kann nicht ersetzt werden."],
    [2004, "Unterschiedliche Maßeinheiten können nicht addiert werden."],
    [2011, "Dieses Produkt gehört nicht zu deinem Unternehmen"],
    [2012, "Diese Maßeinheit kann für dieses Produkt nicht verwendet werden."],
    [2013, "Man kann nur ein Produkt mit einem Produkt mit gleicher Maßeinheit ersetzen."],
    [2014, "Eine bestätigte Erfassung kann nicht gelöscht werden, wenn sie noch nicht in das Journal eingetragen wurde."],
    [2015, "Erfassung kann nicht in das Journal eingetragen werden."],
    [2016, "Produkt wurde deaktiviert und kann nicht mehr hinzugefügt werden."],
    [2021, "Ursprungs- und Ziellager können nicht das gleiche sein."],
    [2022, "Lager muss aus dem gleichen Unternehmen sein."],
    [2031, "Man kann nur eine Kostenstelle aus dem gleichen Unternehmen zuweisen."],
    [2041, "Das Lager ist nicht leer. Nur ein leeres Lager kann gelöscht werden."],
    [2051, "Die Eingabe kann nur einmal korrigiert werden."],
    // Authorization
    [3001, "Du hast nicht die benötigtes Zugriffsrechte für diese Aktion"],
    [3002, "Du hast nur Zugriff auf dein eigenes Unternehmen."],
    [3003, "Du hast nur Zugriff auf deine eigenen Nutzerdaten."],
    [3004, "Du kannst nur eine eigene Id angeben."],
    // Repository
    [4001, "Nicht gefunden."],
    [4002, "Speicher fehlgeschlagen. Bitte später noch einmal versuchen."],
    [4003, "Laden fehlgeschlagen. Bitte später noch einmal versuchen."],
    [4004, "Löschen fehlgeschlagen. Bitte später noch einmal versuchen."],
    // External Service
    [5001, "Der E-Mail Dienst ist gerade nicht verfügbar."],
    [5002, "Dienst nicht verfügbar. Bitte noch einmal versuchen."],
])

export function tTopic(topic: number): string {
    switch (topic) {
        case 0:
            return "Neues Produkt angefordert"
        default:
            return ""
    }
}