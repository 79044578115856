import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface AccessTokenState {
    value: string | null
}

const initialState: AccessTokenState = {value: null}

export const accessTokenSlice = createSlice({
    name: 'accessToken',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<string>) => {
            state.value = action.payload
        },
        reset: (state) => {
            state.value = null
        },
    }
})

export const {set, reset} = accessTokenSlice.actions

export default accessTokenSlice.reducer
