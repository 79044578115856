import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {msalConfig} from "../../shared/authentication/msal";
import {store} from "../../store";
import {set} from "../../shared/authentication/accessTokenSlice";
import {InteractionRequiredAuthError, PublicClientApplication} from "@azure/msal-browser";

export const msgraphApi = createApi({
    reducerPath: 'msgraphApi',
    baseQuery: fetchBaseQuery({
        baseUrl: "https://graph.microsoft.com/v1.0/",
        prepareHeaders: async (headers) => {
            const pca = new PublicClientApplication(msalConfig)
            const account = pca.getAllAccounts()[0]
            const request = {scopes: ["User.Read"], account}
            try {
                let response = await pca.acquireTokenSilent(request);
                store.dispatch(set(response.accessToken))
                headers.set("Authorization", `Bearer ${response.accessToken}`)
                return headers
            } catch (e) {
                // This is needed in case the refresh token is expired or no session data is set
                if (e instanceof InteractionRequiredAuthError) {
                    pca.acquireTokenRedirect(request).catch(e => console.log(e))
                }
            }
        },
    }),
    tagTypes: ["Users"],
    endpoints: (build) => ({
        showUser: build.query<MsUserData, ShowMsUserRequest>({
            query: (request) => ({
                url: `users/${request.id}?\$select=id,displayName`
            }),
            providesTags: result => result ? [{type: "Users", id: result.id}] : []
        }),
    })
})

export const {
    useShowUserQuery,
} = msgraphApi

export interface MsUserData {
    id: string
    displayName: string
}

export interface ShowMsUserRequest {
    id: string
}

