import {useState} from "react";
import {
    useDeleteStoreMutation,
    useRegisterStoreMutation,
    useShowCompanyListQuery,
    useShowStoreListQuery,
    useUpdateStoreMutation
} from "../../services/backend/api";
import {Heading2} from "../../shared/components/headings";
import {t} from "../../lang/lang";
import {
    Table,
    TableActions,
    TableActionSearch,
    TableBody,
    TableBodyCell,
    TableBodyRow,
    TableHead,
    TableHeadCell,
    TableHeadRow
} from "../../shared/components/table";
import {v4} from "uuid";
import {FormCard, FormHeading, FormSelectInput, FormSubmitButton, FormTextInput} from "../../shared/components/form";
import {DangerAlert, SuccessAlert} from "../../shared/components/alerts";
import {errorMessageIn, validationErrorsFor} from "../../services/backend/errors";
import {DeleteIcon, EditIcon} from "../../shared/components/icons";
import {Modal} from "../../shared/components/modal";
import {useActiveCompany} from "../../shared/active-company/active-company-hook";
import {Dialog} from "@headlessui/react";
import {logoForStore, Store, storeTypes} from "../../services/backend/store";

export default function StoresRoute() {
    return (<div className="space-y-6 pb-3">
        <Heading2>{t(`Lagerverwaltung`)}</Heading2>
        <StoreList/>
    </div>)
}

function StoreList() {
    const {activeCompanyId} = useActiveCompany()
    const {data: stores, isSuccess} = useShowStoreListQuery({
        companyId: activeCompanyId ?? "",
        orderBy: "name asc",
    })
    const [search, setSearch] = useState("")

    if (!isSuccess) {
        return <></>
    }

    const filteredStores = stores.filter((store) => {
        if (search == "") {
            return true
        }
        return store.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
    })

    return (<div className="">
        <TableActions>
            <TableActionSearch value={search} onChange={(e) => setSearch(e.target.value)}
                               placeholder={t("Tabelle durchsuchen")}/>
            <Modal openIcon={(
                <div
                    className="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    {t("Neues Lager")}
                </div>
            )}>
                <NewStore/>
            </Modal>
        </TableActions>
        <Table shadow={true}>
            <TableHead>
                <TableHeadRow>
                    <TableHeadCell>{t("Name")}</TableHeadCell>
                    <TableHeadCell>{t("Typ")}</TableHeadCell>
                    <TableHeadCell>{t("Aktionen")}</TableHeadCell>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {
                    filteredStores.map((store, index) => (
                        <TableBodyRow key={index}>
                            <TableBodyCell>{store.name}</TableBodyCell>
                            <TableBodyCell>
                                <div className={"flex items-center justify-start"}>
                                    <span className="mr-3">{logoForStore(store.type)}</span>
                                    {t(store.type)}
                                </div>
                            </TableBodyCell>
                            <TableBodyCell>
                                <div className="flex items-center justify-start space-x-2">
                                    <Modal openIcon={<EditIcon size={4}/>}><EditStore store={store}/></Modal>
                                    <DeleteStore store={store}/>
                                </div>
                            </TableBodyCell>
                        </TableBodyRow>
                    ))
                }
            </TableBody>
        </Table>
    </div>)
}

function NewStore() {
    const {activeCompanyId} = useActiveCompany()
    const [name, setName] = useState("")
    const [type, setType] = useState("")
    const [companyId, setCompanyId] = useState(activeCompanyId ?? "")
    const [register, {isLoading, isSuccess, error, reset}] = useRegisterStoreMutation()
    const {data: companies, isSuccess: companiesIsSuccess} = useShowCompanyListQuery({})
    if (!companiesIsSuccess) {
        return <></>
    }

    const canRegister = !isLoading && name.length >= 3 && type != "" && companyId != ""

    const updateName = (newName: string) => {
        reset()
        setName(newName)
    }

    const updateType = (newType: string) => {
        reset()
        setType(newType)
    }

    const updateCompanyId = (newCompanyId: string) => {
        reset()
        setCompanyId(newCompanyId)
    }

    const onSubmit = () => {
        if (canRegister) {
            register({id: v4(), name, type, companyId}).unwrap().then(() => {
                setName("")
                setType("")
                setCompanyId(activeCompanyId ?? "")
            })
        }
    }

    return (<>
        <FormCard>
            <FormHeading>{t("Neues Lager registrieren")}</FormHeading>
            <DangerAlert>{errorMessageIn(error)}</DangerAlert>
            <FormTextInput
                label={t("Name")}
                value={name}
                placeholder={t("Name eingeben")}
                onChange={(e) => updateName(e.target.value)}
                errors={validationErrorsFor("name", error)}
            />
            <FormSelectInput
                label={t("Typ")}
                value={type}
                options={storeTypes}
                onChange={(e) => updateType(e.target.value)}
                errors={validationErrorsFor("type", error)}
            />
            <FormSelectInput
                label={t("Unternehmen")}
                disabled={true}
                value={companyId}
                options={companies.map((c) => ({value: c.id, display: c.name}))}
                onChange={(e) => updateCompanyId(e.target.value)}
                errors={validationErrorsFor("companyId", error)}
            />
            <SuccessAlert text={isSuccess ? t("Lager registriert") : ""}/>
            <FormSubmitButton onClick={onSubmit} disabled={!canRegister}>{t("Registrieren")}</FormSubmitButton>
        </FormCard>
    </>)
}

function EditStore(props: { store: Store }) {
    const [name, setName] = useState(props.store.name)
    const [type, setType] = useState(props.store.type)
    const [update, {isLoading, isSuccess, error, reset}] = useUpdateStoreMutation()

    const canUpdate = () => !isLoading && name.length >= 3 && type != ""

    const updateName = (newName: string) => {
        reset()
        setName(newName)
    }

    const updateType = (newType: string) => {
        reset()
        setType(newType)
    }

    const onSubmit = () => {
        if (canUpdate()) {
            update({id: props.store.id, name, type})
        }
    }

    return (<>
        <FormCard>
            <FormHeading>{t("Lager bearbeiten")}</FormHeading>
            <DangerAlert>{errorMessageIn(error)}</DangerAlert>
            <FormTextInput
                label={t("Name")}
                value={name}
                placeholder={t("Name eingeben")}
                onChange={(e) => updateName(e.target.value)}
                errors={validationErrorsFor("name", error)}
            />
            <FormSelectInput
                label={t("Typ")}
                value={type}
                options={storeTypes}
                onChange={(e) => updateType(e.target.value)}
                errors={validationErrorsFor("type", error)}
            />
            <SuccessAlert text={isSuccess ? t("Lager aktualisiert") : ""}/>
            <FormSubmitButton onClick={onSubmit} disabled={!canUpdate()}>{t("Aktualisieren")}</FormSubmitButton>
        </FormCard>
    </>)
}

function DeleteStore(props: { store: Store }) {
    const [deleteStore, {error, isError, isLoading, reset}] = useDeleteStoreMutation()

    const canDelete = !isLoading

    const doDelete = () => {
        if (canDelete) {
            deleteStore({
                storeId: props.store.id,
            })
        }
    }

    return (<>
        <button onClick={doDelete}><DeleteIcon size={5}/></button>
        <Dialog
            open={isError}
            onClose={() => reset()}
            className="fixed z-50"
        >
            {/* Backdrop */}
            <div className="fixed inset-0 bg-black/30" aria-hidden="true"/>

            <div className="fixed inset-0 flex items-center justify-center p-4">
                <Dialog.Panel className="relative w-full h-full max-w-md md:h-auto">
                    <button type="button"
                            onClick={() => reset()}
                            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                            data-modal-hide="authentication-modal">
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clipRule="evenodd"></path>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div
                        className="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                        role="alert">
                        <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor"
                             viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                  clipRule="evenodd"></path>
                        </svg>
                        <span className="sr-only">Info</span>
                        <div className="pr-10">
                            {errorMessageIn(error)}
                        </div>
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>
    </>)
}