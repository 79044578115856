import {useParams} from "react-router-dom";
import {useShowEntryListQuery} from "../../../services/backend/api";
import {ReactElement, useState} from "react";
import {t} from "../../../lang/lang";
import {
    Table,
    TableActions,
    TableActionSearch,
    TableBody,
    TableBodyCell,
    TableBodyRowHeadCell,
    TableHead,
    TableHeadCell,
    TableHeadRow,
    TablePagination
} from "../../../shared/components/table";
import Username from "../../../shared/username/Username";
import {IconForTrigger, ShowLedgerRequest} from "../../../services/backend/ledger";
import {HiChevronRight} from "react-icons/hi2";

export default function LedgerRoute() {
    const {storeId} = useParams()
    const [data, setData] = useState<ShowLedgerRequest>({
        storeId: storeId ?? "",
        search: "",
        from: "",
        to: "",
        page: 1,
        pageSize: 10,
    })
    const [request, setRequest] = useState<ShowLedgerRequest>({
        storeId: storeId ?? "",
        search: "",
        from: "",
        to: "",
        page: 1,
        pageSize: 10,
    })
    const {data: pageData, isSuccess, isLoading, refetch} = useShowEntryListQuery(request)

    if (!isSuccess) {
        return <></>
    }

    function nextPage() {
        let next = request.page + 1
        if (next > pageData!.totalPages) {
            // do nothing
            return
        }
        setRequest({
            storeId: storeId ?? "",
            search: request.search,
            from: request.from,
            to: request.to,
            page: next,
            pageSize: request.pageSize,
        })
    }

    function previousPage() {
        let previous = request.page - 1
        if (previous <= 0) {
            // do nothing
            return
        }
        setRequest({
            storeId: storeId ?? "",
            search: request.search,
            from: request.from,
            to: request.to,
            page: previous,
            pageSize: request.pageSize,
        })
    }

    function selectPage(page: number) {
        if (page > pageData!.totalPages) {
            // do nothing
            return
        }
        if (page <= 0) {
            // do nothing
            return
        }
        setRequest({
            storeId: storeId ?? "",
            search: request.search,
            from: request.from,
            to: request.to,
            page: page,
            pageSize: request.pageSize,
        })
    }

    let rows: ReactElement[] = []
    pageData.entries.forEach((item, index) => {
        rows.push(
            <tr className={((index + 1) == pageData.entries.length) ? "bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600" : "bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"}
                key={index}>
                <TableBodyRowHeadCell>
                    {item.productName + (item.productManufacturerName != "" ? ", " + item.productManufacturerName : "")}
                </TableBodyRowHeadCell>
                <TableBodyCell textRight={true}>
                    {(new Date(item.createdAt)).toLocaleDateString()}
                </TableBodyCell>
                <TableBodyCell textRight={true}>
                    {item.count}
                </TableBodyCell>
                <TableBodyCell>
                    {t(item.measure)}
                </TableBodyCell>
                <TableBodyCell>
                    <Username id={item.userId}/>
                </TableBodyCell>
                <TableBodyCell>
                    <div className="flex items-center justify-start space-x-2">
                        <IconForTrigger triggerType={item.triggerType} className="h-6"/>
                        <span>{t(item.triggerType)}</span>
                    </div>
                </TableBodyCell>
                <TableBodyCell>
                    <div className="whitespace-nowrap">{item.comment}</div>
                </TableBodyCell>
            </tr>
        )
    })

    return (<div className="mt-1">
        <TableActions>
            <form onSubmit={(e) => {
                e.preventDefault()
                setRequest({
                    ...request,
                    search: data.search,
                    from: inputDateToRFC3339(data.from),
                    to: inputDateToRFC3339(data.to),
                })
            }}>
                <div className="flex items-center">
                    <TableActionSearch
                        value={data.search}
                        onChange={(e) => setData({...data, search: e.target.value})}
                        placeholder={t("Tabelle durchsuchen")}
                        loading={isLoading}
                    />
                    <input type="date"
                           value={data.from}
                           className="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                           onChange={(e) => setData({...data, from: e.target.value})}
                    />
                    <div>
                        <HiChevronRight className="mx-1 w-6 h-6"/>
                    </div>
                    <input type="date"
                           value={data.to}
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                           onChange={(e) => setData({...data, to: e.target.value})}
                    />
                    <button type="submit"
                            className="ml-4 inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-2 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                    >
                        {t("Suchen")}
                    </button>
                </div>
            </form>
        </TableActions>
        <Table shadow={true}>
            <TableHead>
                <TableHeadRow>
                    <TableHeadCell>{t("Produkt")}</TableHeadCell>
                    <TableHeadCell>{t("Datum")}</TableHeadCell>
                    <TableHeadCell>{t("Änderung")}</TableHeadCell>
                    <TableHeadCell>{t("Einheit")}</TableHeadCell>
                    <TableHeadCell>{t("Mitarbeiter")}</TableHeadCell>
                    <TableHeadCell>{t("Auslöser")}</TableHeadCell>
                    <TableHeadCell>{t("Anmerkung")}</TableHeadCell>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {pageData.entries.length > 0
                    ? rows.length > 0
                        ? rows
                        : <tr>
                            <td colSpan={8} className="text-center">{t("Nicht gefunden")}</td>
                        </tr>
                    : <tr>
                        <td colSpan={8} className="text-center">{t("Keine Einträge")}</td>
                    </tr>}
            </TableBody>
        </Table>
        <TablePagination pageData={{
            page: pageData.page,
            pageSize: pageData.pageSize,
            pageItemCount: pageData.entries.length,
            totalItemCount: pageData.total,
            totalPageCount: pageData.totalPages,
        }} next={nextPage} previous={previousPage} selectPage={selectPage}/>
    </div>)
}

function inputDateToRFC3339(string: string): string {
    if (string == "") {
        return ""
    }
    return new Date(Date.parse(string)).toISOString()
}
