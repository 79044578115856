import {ChangeEvent, MouseEventHandler, PropsWithChildren, ReactNode} from "react";
import {t} from "../../lang/lang";

export function FormCard(props: PropsWithChildren) {
    return (
        <div
            className="p-4 bg-white border border-gray-200 rounded-lg shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
            <form className="space-y-6" action="#" onSubmit={(e) => e.preventDefault()}>
                {props.children}
            </form>
        </div>
    )
}

export function FormHeading(props: PropsWithChildren) {
    return <h5 className="text-xl font-medium text-gray-900 dark:text-white">{props.children}</h5>
}

export function FormTextInput(props: {
    value: string,
    label?: string,
    placeholder: string,
    onChange: (e: ChangeEvent<HTMLInputElement>) => void,
    errors: string[]
}) {
    return (<div>
        <InputLabel text={props.label}/>
        <input
            type="text"
            value={props.value}
            onChange={props.onChange}
            className={
                props.errors.length > 0
                    ? "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400"
                    : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            }
            placeholder={props.placeholder}/>
        <InputErrorMessages errors={props.errors}/>

    </div>)
}

export function FormDateInput(props: { value: string, label?: string, onChange: (newValue: string) => void }) {
    return (<div>
        <InputLabel text={props.label}/>
        <input type="date"
               value={props.value}
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
               onChange={(e) => props.onChange(e.target.value)}
        />
    </div>)
}

export function FormSelectInput(props: {
    value: string,
    options: Option[],
    label?: string,
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void,
    errors: string[],
    disabled?: boolean
}) {
    return (<div>
        <InputLabel text={props.label}/>
        <select
            onChange={props.onChange}
            value={props.value}
            disabled={props.disabled}
            className="bg-gray-50 border border-gray-300 text-gray-900 invalid:text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <option value={""} disabled={true} hidden={true} key={""}></option>
            {
                props.options.map((option) => <option value={option.value}
                                                      key={option.value}>{t(option.display)}</option>)
            }
        </select>
        <InputErrorMessages errors={props.errors}/>
    </div>)
}

export function FormInputHelperText(props: PropsWithChildren) {
    return (
        <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
            {props.children}
        </p>
    )
}

export interface Option {
    value: string
    display: string
}

export function InputLabel(props: { text?: string }) {
    return (<div>{
        props.text
            ? <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{props.text}</label>
            : null
    }</div>)
}

export function InputErrorMessages(props: { errors: string[] }) {
    return (<div>{
        props.errors.map((error, index) => (
            <p className="mt-2 text-sm text-red-600 dark:text-red-500" key={index}>
                {error}
            </p>
        ))
    }</div>)
}

export function FormSubmitButton(props: {
    children: ReactNode,
    onClick: MouseEventHandler<HTMLButtonElement>,
    disabled?: boolean
}) {
    if (props.disabled == true) {
        return <button type="button"
                       className="text-white bg-blue-400 dark:bg-blue-500 cursor-not-allowed font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                       disabled>{props.children}</button>
    }
    return <button type="button"
                   onClick={props.onClick}
                   className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        {props.children}
    </button>
}