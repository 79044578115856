import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ActiveCompanyState {
    value: string | null
}

const initialState = {value: null} as ActiveCompanyState

export const activeCompanySlice = createSlice({
    name: 'activeCompany',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<string>) => {
            state.value = action.payload
        },
        reset: (state) => {
            state.value = null
        }
    }
})

export const {set, reset} = activeCompanySlice.actions

export default activeCompanySlice.reducer
