import {
    Table,
    TableActions,
    TableBody,
    TableBodyCell,
    TableBodyRow,
    TableBodyRowHeadCell,
    TableHead,
    TableHeadCell,
    TableHeading,
    TableHeadRow
} from "../../shared/components/table";
import {t} from "../../lang/lang";
import {Modal} from "../../shared/components/modal";
import {useState} from "react";
import {
    useDeleteAzureGroupMutation,
    useShowAzureGroupListQuery,
    useShowCompanyListQuery,
    useUpdateAzureGroupMutation
} from "../../services/backend/api";
import {FormCard, FormHeading, FormSelectInput, FormSubmitButton, FormTextInput} from "../../shared/components/form";
import {DangerAlert, SuccessAlert} from "../../shared/components/alerts";
import {errorMessageIn, validationErrorsFor} from "../../services/backend/errors";
import Spinner from "../../shared/components/loading";
import {DeleteIcon, EditIcon} from "../../shared/components/icons";
import Paragraph from "../../shared/components/paragraph";
import {AzureGroup} from "../../services/backend/azure";

export default function AzureGroups() {
    const {data: groups, isSuccess} = useShowAzureGroupListQuery({})

    if (!isSuccess) {
        return <></>
    }

    return <div>
        <TableActions>
            <TableHeading text={t("Azure AD Group Matching")}/>
            <Modal openIcon={<div
                className="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                {t("Neue Gruppe")}
            </div>}>
                <NewGroup/>
            </Modal>
        </TableActions>
        <Paragraph>
            {t("Nutzer geben über ihre Gruppenzugehörigkeit an welchen Unternehmen sie zuzuordnen sind. " +
                "Gruppen die hier nicht registriert sind finden keine Beachtung. Durch die Konfiguration ist es möglich, " +
                "dass ein Nutzer einem oder mehreren Unternehmen zugeordnet ist.")}
        </Paragraph>
        <div className="pt-3"/>
        <Table shadow={true}>
            <TableHead>
                <TableHeadRow>
                    <TableHeadCell>{t("Group Name")}</TableHeadCell>
                    <TableHeadCell>{t("Group Id")}</TableHeadCell>
                    <TableHeadCell>{t("Unternehmen")}</TableHeadCell>
                    <TableHeadCell>{t("Aktionen")}</TableHeadCell>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {
                    groups.map((g, i) => <TableBodyRow key={i}>
                        <TableBodyRowHeadCell>{g.name}</TableBodyRowHeadCell>
                        <TableBodyCell>{g.id}</TableBodyCell>
                        <TableBodyCell>{g.company.name}</TableBodyCell>
                        <TableBodyCell>
                            <div className="flex items-center space-x-3">
                                <Modal openIcon={<EditIcon size={4}/>}>
                                    <UpdateGroup group={g}/>
                                </Modal>
                                <DeleteGroup id={g.id}/>
                            </div>
                        </TableBodyCell>
                    </TableBodyRow>)
                }
            </TableBody>
        </Table>
    </div>
}

function NewGroup() {
    const [name, setName] = useState("")
    const [id, setId] = useState("")
    const [companyId, setCompanyId] = useState("")
    const [create, {isLoading, isSuccess, error, reset}] = useUpdateAzureGroupMutation()
    const {data: companies, isSuccess: companiesIsSuccess} = useShowCompanyListQuery({})

    if (!companiesIsSuccess) {
        return <></>
    }

    const updateName = (newName: string) => {
        reset()
        setName(newName)
    }
    const updateId = (newId: string) => {
        reset()
        setId(newId)
    }
    const updateCompanyId = (newCompanyId: string) => {
        reset()
        setCompanyId(newCompanyId)
    }

    const canUpdate = name != "" && id != "" && companyId != "" && !isLoading

    const onCreate = () => {
        if (canUpdate) {
            create({id, name, companyId})
        }
    }

    return (<>
        <FormCard>
            <FormHeading>{t("Gruppe registrieren")}</FormHeading>
            <DangerAlert>{errorMessageIn(error)}</DangerAlert>
            <FormTextInput
                label={t("Name")}
                value={name}
                placeholder={t("Name eingeben")}
                onChange={(e) => updateName(e.target.value)}
                errors={validationErrorsFor("name", error)}
            />
            <FormTextInput
                label={t("Gruppen Id")}
                value={id}
                placeholder={t("Gruppen Id eingeben")}
                onChange={(e) => updateId(e.target.value)}
                errors={validationErrorsFor("id", error)}
            />
            <FormSelectInput
                label={t("Unternehmen")}
                value={companyId}
                options={companies.map((company) => ({value: company.id, display: company.name}))}
                onChange={(e) => updateCompanyId(e.target.value)}
                errors={validationErrorsFor("companyId", error)}
            />
            <SuccessAlert text={isSuccess ? t("Gruppe erstellt") : ""}/>
            {isLoading ? <Spinner/> : null}
            <FormSubmitButton onClick={onCreate} disabled={!canUpdate}>{t("Registrieren")}</FormSubmitButton>
        </FormCard>
    </>)
}


function UpdateGroup(props: { group: AzureGroup }) {
    const [name, setName] = useState(props.group.name)
    const [id, setId] = useState(props.group.id)
    const [companyId, setCompanyId] = useState(props.group.companyId)
    const [update, {isLoading, isSuccess, error, reset}] = useUpdateAzureGroupMutation()
    const {data: companies, isSuccess: companiesIsSuccess} = useShowCompanyListQuery({})

    if (!companiesIsSuccess) {
        return <></>
    }

    const updateName = (newName: string) => {
        reset()
        setName(newName)
    }
    const updateId = (newId: string) => {
        reset()
        setId(newId)
    }
    const updateCompanyId = (newCompanyId: string) => {
        reset()
        setCompanyId(newCompanyId)
    }

    const canUpdate = name != "" && id != "" && companyId != "" && !isLoading

    const onUpdate = () => {
        if (canUpdate) {
            update({id, name, companyId})
        }
    }

    return (<>
        <FormCard>
            <FormHeading>{t("Gruppe aktualisieren")}</FormHeading>
            <DangerAlert>{errorMessageIn(error)}</DangerAlert>
            <FormTextInput
                label={t("Name")}
                value={name}
                placeholder={t("Name eingeben")}
                onChange={(e) => updateName(e.target.value)}
                errors={validationErrorsFor("name", error)}
            />
            <FormTextInput
                label={t("Gruppen Id")}
                value={id}
                placeholder={t("Gruppen Id eingeben")}
                onChange={(e) => updateId(e.target.value)}
                errors={validationErrorsFor("id", error)}
            />
            <FormSelectInput
                label={t("Unternehmen")}
                value={companyId}
                options={companies.map((company) => ({value: company.id, display: company.name}))}
                onChange={(e) => updateCompanyId(e.target.value)}
                errors={validationErrorsFor("companyId", error)}
            />
            <SuccessAlert text={isSuccess ? t("Gruppe aktualisiert") : ""}/>
            {isLoading ? <Spinner/> : null}
            <FormSubmitButton onClick={onUpdate} disabled={!canUpdate}>{t("Aktualisieren")}</FormSubmitButton>
        </FormCard>
    </>)
}

function DeleteGroup(props: { id: string }) {
    const [doDelete] = useDeleteAzureGroupMutation()

    return <button onClick={() => doDelete({groupId: props.id})}><DeleteIcon/></button>
}