import {Heading2} from "../../shared/components/headings";
import {t, tTopic} from "../../lang/lang";
import {FormCard, FormHeading, FormSubmitButton, FormTextInput} from "../../shared/components/form";
import {useState} from "react";
import {
    useShowEmailAddressQuery,
    useShowSubscriptionListQuery,
    useUpdateEmailAddressMutation,
    useUpdateSubscriptionMutation
} from "../../services/backend/api";
import {errorMessageIn, validationErrorsFor} from "../../services/backend/errors";
import {DangerAlert, SuccessAlert} from "../../shared/components/alerts";
import {useAuth} from "../../shared/authentication/provider";
import Spinner from "../../shared/components/loading";
import {useActiveCompany} from "../../shared/active-company/active-company-hook";
import {
    Table,
    TableActions,
    TableBody,
    TableBodyCell,
    TableBodyRow,
    TableBodyRowHeadCell,
    TableHead,
    TableHeadCell,
    TableHeading,
    TableHeadRow
} from "../../shared/components/table";
import {EmailAddress, Subscription} from "../../services/backend/notification";

export default function NotificationSettingsRoute() {
    return (<div className="space-y-6">
        <Heading2>{t("Benachrichtigungen")}</Heading2>
        <Email></Email>
        <Subscriptions></Subscriptions>
    </div>)
}

function Email() {
    const {user} = useAuth()
    const {data: email, isSuccess} = useShowEmailAddressQuery({userId: user?.id ?? ""})

    if (!isSuccess) {
        return <></>
    }

    return <UpdateEmailAddress current={email}/>
}

function UpdateEmailAddress(props: { current: EmailAddress }) {
    const [email, setEmail] = useState(props.current.email)
    const [update, {isLoading, isSuccess, error, reset}] = useUpdateEmailAddressMutation()

    const onUpdate = () => {
        reset()
        update({
            userId: props.current.userId,
            email: email,
        })
    }

    return (<>
        <FormCard>
            <FormHeading>{t("Email Adresse für Benachrichtigungen")}</FormHeading>
            <DangerAlert>{errorMessageIn(error)}</DangerAlert>
            {isSuccess ? <SuccessAlert text={t("Email aktualisiert")}/> : null}
            <div className="flex w-full space-x-6 justify-between items-top">
                {isLoading ? <Spinner/> : null}
                <div className="flex-grow">
                    <FormTextInput
                        value={email}
                        placeholder={t("Email address eingeben")}
                        onChange={(e) => setEmail(e.target.value)}
                        errors={validationErrorsFor("email", error)}/>
                </div>
                <div>
                    <FormSubmitButton onClick={() => onUpdate()}>{t("Aktualisieren")}</FormSubmitButton>
                </div>
            </div>
        </FormCard>
    </>)
}

function Subscriptions() {
    const {user} = useAuth()
    const {activeCompanyId} = useActiveCompany()
    const {data: subs, isSuccess} = useShowSubscriptionListQuery({
        userId: user?.id ?? "",
        companyId: activeCompanyId ?? ""
    });

    if (!isSuccess) {
        return <></>
    }

    return <div>
        <TableActions>
            <TableHeading text={t("Abonnements")}></TableHeading>
        </TableActions>
        <Table shadow={true}>
            <TableHead>
                <TableHeadRow>
                    <TableHeadCell>{t("Thema")}</TableHeadCell>
                    <TableHeadCell>{t("Email")}</TableHeadCell>
                    <TableHeadCell>{t("Portal")}</TableHeadCell>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {
                    subs.map((sub, i) => <SubscriptionRow sub={sub} key={i}/>)
                }
            </TableBody>
        </Table>
    </div>
}

function SubscriptionRow(props: { sub: Subscription }) {
    const [mail, setMail] = useState(props.sub.mail)
    const [portal, setPortal] = useState(props.sub.portal)
    const [update, {isLoading}] = useUpdateSubscriptionMutation()

    const onChangeMail = () => {
        if (!isLoading) {
            doUpdate(!mail, portal).then(() => setMail(!mail))
        }
    }

    const onChangePortal = () => {
        if (!isLoading) {
            doUpdate(mail, !portal).then(() => setPortal(!portal))
        }
    }

    const doUpdate = (newMail: boolean, newPortal: boolean) => {
        return update({
            userId: props.sub.userId,
            companyId: props.sub.companyId,
            topic: props.sub.topic,
            portal: newPortal,
            mail: newMail,
        }).unwrap()
    }

    return (<TableBodyRow>
        <TableBodyRowHeadCell>{tTopic(props.sub.topic)}</TableBodyRowHeadCell>
        <TableBodyCell>
            <div className="flex items-center">
                <input type="checkbox"
                       checked={mail}
                       onChange={onChangeMail}
                       className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
            </div>
        </TableBodyCell>
        <TableBodyCell>
            <div className="flex items-center">
                <input type="checkbox"
                       checked={portal}
                       onChange={onChangePortal}
                       className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
            </div>
        </TableBodyCell>
    </TableBodyRow>)
}