import {NavLink} from "react-router-dom";
import {PropsWithChildren} from "react";

export function TabNavigation(props: PropsWithChildren) {
    return (
    <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
        {props.children}
    </ul>)
}

export function TabNavigationItem(props: {text: string, to: string}) {
    return (
        <li>
            <NavLink
                to={props.to}
                className={
                    ({isActive}) => isActive
                        ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"
                        : "inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                }
            >{props.text}</NavLink>
        </li>
    )
}