import {Heading2} from "../../shared/components/headings";
import {t} from "../../lang/lang";
import {
    Table,
    TableActions,
    TableBody,
    TableBodyCell,
    TableBodyRow,
    TableBodyRowHeadCell,
    TableHead,
    TableHeadCell,
    TableHeading,
    TableHeadRow
} from "../../shared/components/table";
import {
    useRegisterCompanyMutation,
    useShowCompanyListQuery,
    useUpdateCompanyMutation
} from "../../services/backend/api";
import {useState} from "react";
import {EditIcon} from "../../shared/components/icons";
import {Modal} from "../../shared/components/modal";
import {v4} from "uuid";
import {FormCard, FormHeading, FormSubmitButton, FormTextInput,} from "../../shared/components/form";
import {DangerAlert, SuccessAlert} from "../../shared/components/alerts";
import {errorMessageIn, validationErrorsFor} from "../../services/backend/errors";
import AzureGroups from "./azure-groups";
import {Company} from "../../services/backend/company";

export default function CompaniesRoute() {
    return (<div className="space-y-3">
        <Heading2>{t("Unternehmen")}</Heading2>
        <CompanyTable/>
        <div className="pt-3"></div>
        <AzureGroups/>
    </div>)
}

function CompanyTable() {
    const {data: companies, isSuccess} = useShowCompanyListQuery({orderBy: "name asc"})
    if (!isSuccess) {
        return <></>
    }

    return (<div>
        <TableActions>
            <TableHeading text={t("Registrierte Unternehmen")}/>
            <Modal openIcon={
                <div
                    className="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    {t("Neues Unternehmen")}
                </div>
            }>
                <NewCompany/>
            </Modal>
        </TableActions>
        <Table shadow={true}>
            <TableHead>
                <TableHeadRow>
                    <TableHeadCell>{t("Name")}</TableHeadCell>
                    <TableHeadCell>{t("Aktionen")}</TableHeadCell>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {
                    companies.map((company, index) => {
                        return (
                            <TableBodyRow key={index}>
                                <TableBodyRowHeadCell>{company.name}</TableBodyRowHeadCell>
                                <TableBodyCell>
                                    <Modal openIcon={<EditIcon size={4}/>}>
                                        <UpdateCompany company={company}/>
                                    </Modal>
                                </TableBodyCell>
                            </TableBodyRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    </div>)
}

function NewCompany() {
    const [name, setName] = useState("")
    const [register, {isLoading, isSuccess, error, reset}] = useRegisterCompanyMutation()

    const updateName = (newName: string) => {
        reset()
        setName(newName)
    }

    const canRegister = !isLoading && name.length >= 3

    const onRegister = () => {
        if (canRegister) {
            register({
                id: v4(),
                name: name,
            })
        }
    }

    return (<>
        <FormCard>
            <FormHeading>{t("Neues Unternehmen registrieren")}</FormHeading>
            <DangerAlert>{errorMessageIn(error)}</DangerAlert>
            <FormTextInput
                label={t("Name")}
                value={name}
                placeholder={t("Name eingeben")}
                onChange={(e) => updateName(e.target.value)}
                errors={validationErrorsFor("name", error)}
            />
            <SuccessAlert text={isSuccess ? t("Unternehmen registriert") : ""}/>
            <FormSubmitButton onClick={onRegister} disabled={!canRegister}>{t("Registrieren")}</FormSubmitButton>
        </FormCard>
    </>)
}

function UpdateCompany(props: { company: Company }) {
    const [name, setName] = useState(props.company.name)
    const [update, {isLoading, isSuccess, error, reset}] = useUpdateCompanyMutation()

    const updateName = (newName: string) => {
        reset()
        setName(newName)
    }

    const canUpdate = !isLoading && name.length >= 3

    const onUpdate = () => {
        if (canUpdate) {
            update({
                id: props.company.id,
                name: name,
            })
        }
    }

    return (<>
        <FormCard>
            <FormHeading>{t("Unternehmen aktualisieren")}</FormHeading>
            <DangerAlert>{errorMessageIn(error)}</DangerAlert>
            <FormTextInput
                label={t("Name")}
                value={name}
                placeholder={t("Name eingeben")}
                onChange={(e) => updateName(e.target.value)}
                errors={validationErrorsFor("name", error)}
            />
            <SuccessAlert text={isSuccess ? t("Unternehmen aktualisiert") : ""}/>
            <FormSubmitButton onClick={onUpdate} disabled={!canUpdate}>{t("Aktualisieren")}</FormSubmitButton>
        </FormCard>
    </>)
}