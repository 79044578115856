import {useShowStoreListQuery} from "../../services/backend/api";
import {useRef, useState} from "react";
import {t} from "../../lang/lang";
import {
    Table,
    TableActions,
    TableBody,
    TableBodyCell,
    TableBodyRow,
    TableBodyRowHeadCell,
    TableHead,
    TableHeadCell,
    TableHeadRow
} from "../../shared/components/table";
import {useActiveCompany} from "../../shared/active-company/active-company-hook";
import {CSVLink} from "react-csv";
import ReactToPrint from "react-to-print";
import QRCode from "react-qr-code";
import {LogoForCompany} from "../root";
import {Store} from "../../services/backend/store";

export default function QrCodesStores() {
    const {activeCompanyId} = useActiveCompany()
    const {data: stores, isSuccess} = useShowStoreListQuery({companyId: activeCompanyId ?? "", orderBy: "name asc"})
    const [selectedStores, setSelectedStores] = useState<Store[]>([])
    if (!isSuccess) {
        return <></>
    }

    const toggleAll = () => {
        if (selectedStores.length == stores.length) {
            resetSelected()
        } else {
            selectAll()
        }
    }
    const selectAll = () => setSelectedStores(stores)
    const resetSelected = () => setSelectedStores([])
    const isSelected = (id: string) => selectedStores.find((store: Store) => id == store.id) != undefined
    const toggleSelection = (store: Store) => {
        if (isSelected(store.id)) {
            setSelectedStores(selectedStores.filter((item: Store) => item.id != store.id))
        } else {
            setSelectedStores(selectedStores.concat(store))
        }
    }

    const toExportData = (store: Store) => {
        return {
            "ID": store.id,
            "Name": store.name,
            "Type": t(store.type),
            "Company": store.companyName,
        }
    }

    return (<div className="mt-1">
        <TableActions>
            <div className="flex flex-row space-x-2">
                <CSVLink
                    className="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                    filename={"store-list.csv"}
                    data={selectedStores.length > 0 ? selectedStores.map(toExportData) : stores.map(toExportData)}>{t("Auswahl Herunterladen")}</CSVLink>
                <QRCodePrint selectedStores={selectedStores}/>
            </div>
        </TableActions>
        <Table shadow={true}>
            <TableHead>
                <TableHeadRow>
                    <TableHeadCell>
                        <div className="flex items-center">
                            <input type="checkbox"
                                   checked={selectedStores.length == stores.length}
                                   onChange={() => toggleAll()}
                                   className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                        </div>
                    </TableHeadCell>
                    <TableHeadCell>{t("Lager")}</TableHeadCell>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {
                    stores.map((store, index) => {
                        return (
                            <TableBodyRow key={index}>
                                <TableBodyCell width={"w-4"}>
                                    <div className="flex items-center">
                                        <input type="checkbox"
                                               checked={isSelected(store.id)}
                                               onChange={() => toggleSelection(store)}
                                               className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    </div>
                                </TableBodyCell>
                                <TableBodyRowHeadCell>{t(store.name)}</TableBodyRowHeadCell>
                            </TableBodyRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    </div>)
}

function QRCodePrint(props: { selectedStores: Store[] }) {
    const printComponentRef = useRef(null);

    return (
        <div>
            <ReactToPrint
                trigger={() =>
                    <button
                        className="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                        {t("Auswahl Drucken")}
                    </button>
                }
                content={() => printComponentRef.current}/>
            <div className="hidden print:block" ref={printComponentRef}>
                <div className="p-4 grid grid-cols-3 gap-4">
                    {
                        props.selectedStores.map((store, index) => (
                            <QRCodeCard key={index} store={store}/>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

function QRCodeCard(props: { store: Store }) {
    return (
        <div
            className="flex w-60 h-20 items-center bg-white border border-gray-200 rounded-lg break-inside-avoid-page">
            <div className="p-2 w-20">
                <QRCode
                    size={512}
                    style={{height: "auto", maxWidth: "100%", width: "100%"}}
                    value={props.store.id}
                    viewBox={`0 0 512 512`}
                />
            </div>
            <div className="w-40 py-2 flex flex-col items-start">
                <LogoForCompany companyId={props.store.companyId} className={"h-6"}/>
                <div className="mt-2 h-10">
                    <h5 className="text-sm font-bold tracking-tight text-gray-900">{props.store.name}</h5>
                </div>
            </div>
        </div>
    )
}
