import {Outlet, useParams} from "react-router-dom";
import {t} from "../../../lang/lang";
import {useShowStoreQuery} from "../../../services/backend/api";
import {TabNavigation, TabNavigationItem} from "../../../shared/components/tab-navigation";
import {Heading3} from "../../../shared/components/headings";

export default function StoreRoute() {
    const {storeId} = useParams()
    const {data: store, isSuccess} = useShowStoreQuery({storeId: storeId ?? ""})
    if (!isSuccess) {
        return <div></div>
    }

    return (
        <div>
            <Heading3>{store.name}</Heading3>
            <p className="mt-3"></p>
            <TabNavigation>
                <TabNavigationItem text={t('Lagerbestand')} to={`inventory`}/>
                <TabNavigationItem text={t('Journal')} to={`ledger`}/>
            </TabNavigation>
            <div className="pt-3 sm:px-2">
                <Outlet/>
            </div>
        </div>
    )
}