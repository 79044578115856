import {PropsWithChildren, useEffect} from "react";
import {useActiveCompany} from "./active-company-hook";
import {useShowUserCompaniesQuery} from "../../services/backend/api";
import {Heading1} from "../components/headings";
import {t} from "../../lang/lang";
import {errorPageFor} from "../../routes/error-pages";
import {FullPageSpinner} from "../components/loading";
import {LogoForCompany} from "../../routes/root";
import {ADMINISTRATOR, userHasRole} from "../../services/backend/user";
import CompaniesRoute from "../../routes/companies/companies";
import {useAuth} from "../authentication/provider";
import Paragraph from "../components/paragraph";

export default function ActiveCompany(props: PropsWithChildren) {
    const {activeCompanyId} = useActiveCompany()

    if (activeCompanyId == null) {
        return <SelectCompany></SelectCompany>
    }

    return <>{props.children}</>
}

function SelectCompany() {
    const {user} = useAuth()
    const {data: companies, isSuccess, isLoading, error} = useShowUserCompaniesQuery()
    const {setActiveCompany} = useActiveCompany()

    useEffect(() => {
        if (isSuccess && companies.length == 1) {
            setActiveCompany(companies[0])
        }
    }, [companies])

    if (isLoading) {
        return <FullPageSpinner/>
    }

    if (error || !isSuccess) {
        return errorPageFor(error);
    }

    if (companies.length == 0) {
        if (user && userHasRole(user, ADMINISTRATOR)) {
            // This may be the case:
            // * on initial use of the app
            // * if all groups have been deleted
            // * an owner is not assigned to any groups
            // In any of those cases the owner should be
            // able to set up companies, and match azure
            // groups for future authorization.
            return <div className="p-6 space-y-3">
                <Heading1>{t("App konfigurieren")}</Heading1>
                <Paragraph>
                    {t("Diese Seite wird angezeigt, da entweder die App zum ersten Mal gestartet wird oder da Sie zwar authentifiziert sind, es aber keine Gruppen gibt welche Sie einem Unternehmen zuordnen.")}
                </Paragraph>
                <CompaniesRoute/>
                <div className="pt-3">
                    <button onClick={() => window.location.reload()}
                            className="inline-flex text-white bg-gray-500 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">
                        {t("Neu Laden")}
                    </button>
                </div>
            </div>
        }
        return errorPageFor({status: 403})
    }

    return (
        <div className="flex justify-center pt-12">
            <div
                className="w-full max-w-md p-4 bg-white border rounded-lg shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                <div className="flex items-center justify-between mb-4">
                    <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">{t("Bitte Unternehmen auswählen:")}</h5>
                </div>
                <div className="flow-root">
                    <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
                        {companies.map((id, i) => <CompanyOption id={id} key={i}/>)}
                    </ul>
                </div>
            </div>
        </div>
    )
}

function CompanyOption(props: { id: string }) {
    const {setActiveCompany} = useActiveCompany()

    return (
        <li className="py-3 sm:py-4">
            <div className="flex items-center space-x-4 justify-center">
                <button type="button"
                        onClick={() => setActiveCompany(props.id)}>
                    <LogoForCompany companyId={props.id} className={"h-6 sm:h-9"}/>
                </button>
            </div>
        </li>
    )
}