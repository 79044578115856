import {NavLink, useParams} from "react-router-dom";
import {Heading2, Heading3, Heading5} from "../../../shared/components/headings";
import {t} from "../../../lang/lang";
import {Table, TableBody, TableBodyRow, TableHead, TableHeadCell, TableHeadRow} from "../../../shared/components/table";
import {PrinterIcon} from "../../../shared/components/icons";
import {useShowCostCenterConsumptionQuery} from "../../../services/backend/api";
import Spinner from "../../../shared/components/loading";
import {DangerAlert} from "../../../shared/components/alerts";
import {errorMessageIn} from "../../../services/backend/errors";

export default function CostCenterConsumptionRoute() {
    const {costCenterId, from, to} = useParams()
    const {data: report, isSuccess, isLoading, error} = useShowCostCenterConsumptionQuery({
        costCenterId: costCenterId ?? "",
        from: from ? inputDateToRFC3339(from!) : "",
        to: to ? inputDateToRFC3339(to!) : "",
    })

    if (isLoading) {
        return <Spinner/>
    }

    if (error) {
        return <div>
            <DangerAlert>{errorMessageIn(error)}</DangerAlert>
            <NavLink to={`/reporting`}/>
        </div>
    }

    if (!isSuccess) {
        return <></>
    }

    return (<div className="p-6 space-y-3">
        <Heading2>{t('Kostenstellen Bericht')}</Heading2>
        <button type="button"
                onClick={() => window.print()}
                className="fixed top-6 right-6 print:hidden text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            <div className="flex items-center space-x-3">
                <PrinterIcon/>
                <span>{t("Drucken")}</span>
            </div>
        </button>
        <Heading3>{report.costCenter.name}</Heading3>
        <div className="flex items-center justify-start space-x-6">
            <Heading5>{`Von: ${new Date(report.from).toLocaleDateString()}`}</Heading5>
            <Heading5>{`Bis: ${new Date(report.to).toLocaleDateString()}`}</Heading5>
        </div>
        <div className="pt-3">
            <Table shadow={true}>
                <TableHead>
                    <TableHeadRow>
                        <TableHeadCell>{t("Name")}</TableHeadCell>
                        <TableHeadCell>{t("Produkt Nummer")}</TableHeadCell>
                        <TableHeadCell>{t("Hersteller")}</TableHeadCell>
                        <TableHeadCell>{t("Verbrauch")}</TableHeadCell>
                        <TableHeadCell>{t("Einheit")}</TableHeadCell>
                    </TableHeadRow>
                </TableHead>
                <TableBody>
                    {
                        report.products.length == 0
                            ? <TableBodyRow>
                                <td colSpan={2}>{t("Kein Verbrauch")}</td>
                            </TableBodyRow>
                            : null
                    }
                    {
                        report.products.map((item, i) => {
                            return <TableBodyRow key={i}>
                                <td className="py-2 px-3">{item.product.name}</td>
                                <td className="py-2 px-3">{item.product.productNumber}</td>
                                <td className="py-2 px-3">{item.product.manufacturerName}</td>
                                <td className="py-2 px-3 text-right">{item.count}</td>
                                <td className="py-2 px-3">{t(item.product.measure)}</td>
                            </TableBodyRow>
                        })
                    }
                </TableBody>
            </Table>
        </div>
    </div>)
}

function inputDateToRFC3339(string: string): string {
    return new Date(Date.parse(string)).toISOString()
}