import {DangerAlert} from "./components/alerts";
import {NavLink} from "react-router-dom";
import {t} from "../lang/lang";
import {useShowProductListQuery} from "../services/backend/api";
import {useActiveCompany} from "./active-company/active-company-hook";

export default function UnconfirmedProductsAlert() {
    const {activeCompanyId} = useActiveCompany()
    const {data: products, isSuccess} = useShowProductListQuery({companyId: activeCompanyId!, orderBy: "name asc"})
    if (!isSuccess) {
        return <></>
    }
    const unconfirmedProducts = products.filter((product) => product.confirmed == false)
    if (unconfirmedProducts.length == 0) {
        return <></>
    }

    return (<DangerAlert>
        <span>{t("Es gibt unbestätigte Produkte. Die angezeigten Daten können unvollständig sein, solange es unbestätigte Produkte gibt. ")}</span>
        <br/>
        <br/>
        <span><NavLink
            className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
            to={`/products/requested`}>{t("Bitte hier die Produkte bestätigen.")}</NavLink></span>
    </DangerAlert>)
}