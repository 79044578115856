import {
    Table,
    TableActions,
    TableBody,
    TableBodyCell,
    TableBodyRow,
    TableBodyRowHeadCell,
    TableHead,
    TableHeadCell,
    TableHeadRow
} from "../../shared/components/table";
import {t} from "../../lang/lang";
import {useShowProductListQuery} from "../../services/backend/api";
import {useRef, useState} from "react";
import {useActiveCompany} from "../../shared/active-company/active-company-hook";
import {CSVLink} from "react-csv";
import QRCode from "react-qr-code";
import {LogoForCompany} from "../root";
import ReactToPrint from "react-to-print";
import {Product} from "../../services/backend/product";

export default function QrCodesProducts() {
    const {activeCompanyId} = useActiveCompany()
    const {data: products, isSuccess} = useShowProductListQuery({
        companyId: activeCompanyId ?? "",
        confirmed: true,
        orderBy: "name asc",
    })
    const [selectedProducts, setSelectedProducts] = useState<Product[]>([])
    if (!isSuccess) {
        return <></>
    }
    const activeProducts: Product[] = products.filter((product) => product.active)

    const toggleAll = () => {
        if (selectedProducts.length === activeProducts.length) {
            resetSelected()
        } else {
            selectAll()
        }
    }
    const selectAll = () => setSelectedProducts(activeProducts)
    const resetSelected = () => setSelectedProducts([])
    const isSelected = (id: string) => selectedProducts.find((product: Product) => id === product.id) !== undefined
    const toggleSelection = (product: Product) => {
        if (isSelected(product.id)) {
            setSelectedProducts(selectedProducts.filter((item: Product) => item.id !== product.id))
        } else {
            setSelectedProducts(selectedProducts.concat(product))
        }
    }

    const toExportData = (product: Product) => {
        return {
            "ID": product.id,
            "Produktnummer": product.productNumber,
            "Produkt": t(product.name)
        }
    }

    return (<div className="mt-1">
        <TableActions>
            <div className="flex flex-row space-x-2">
                <CSVLink
                    className="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                    filename={"product-list.csv"}
                    data={selectedProducts.length > 0 ? selectedProducts.map(toExportData) : activeProducts.map(toExportData)}>{t("Auswahl Herunterladen")}</CSVLink>
                <QRCodePrint selectedProducts={selectedProducts}/>
            </div>
        </TableActions>
        <Table shadow={true}>
            <TableHead>
                <TableHeadRow>
                    <TableHeadCell>
                        <div className="flex items-center">
                            <input type="checkbox"
                                   checked={selectedProducts.length == activeProducts.length}
                                   onChange={() => toggleAll()}
                                   className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                        </div>
                    </TableHeadCell>
                    <TableHeadCell>{t("Produktnummer")}</TableHeadCell>
                    <TableHeadCell>{t("Produkt")}</TableHeadCell>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {
                    activeProducts.map((product, index) => {
                        return (
                            <TableBodyRow key={index}>
                                <TableBodyCell width={"w-4"}>
                                    <div className="flex items-center">
                                        <input type="checkbox"
                                               checked={isSelected(product.id)}
                                               onChange={() => toggleSelection(product)}
                                               className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    </div>
                                </TableBodyCell>
                                <TableBodyRowHeadCell>{product.productNumber}</TableBodyRowHeadCell>
                                <TableBodyRowHeadCell>{t(product.name)}</TableBodyRowHeadCell>
                            </TableBodyRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    </div>)
}

function QRCodePrint(props: { selectedProducts: Product[] }) {
    const printComponentRef = useRef(null);

    return (
        <div>
            <ReactToPrint
                trigger={() =>
                    <button
                        className="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                        {t("Auswahl Drucken")}
                    </button>
                }
                content={() => printComponentRef.current}/>
            <div className="hidden print:block" ref={printComponentRef}>
                <div className="p-4 grid grid-cols-1 gap-4">
                    {
                        props.selectedProducts.map((product, index) => (
                            <QRCodeCard key={index} product={product}/>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

function QRCodeCard(props: { product: Product }) {
    return (
        <div
            className="flex w-[570px] h-[215px] items-center  bg-white border border-gray-200 break-inside-avoid-page">
            <div className="flex  items-center flex-row">
                <div className="p-4 w-40">
                    <QRCode
                        size={512}
                        style={{height: "auto", maxWidth: "100%", width: "100%"}}
                        value={props.product.id}
                        viewBox={`0 0 512 512`}
                    />
                </div>
                <div className="w-40 py-2 flex flex-col items-start">
                    <LogoForCompany companyId={props.product.companyId} className={"h-10"}/>
                    <div className="mt-2">
                        <h5 className="text-2xl tracking-tight text-gray-900 font-bold">{props.product.name}</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}