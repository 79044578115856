import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {t, tError, tFieldError} from "../../lang/lang";

export interface BackendError {
    type: string
    code: number
    codeName: string
    payload: {}
}

interface FieldError {
    code: number
    codeName: string
}

export function validationErrorsFor(field: string, error: unknown): string[] {
    if (isFetchBaseQueryError(error)) {
        if (isValidationError(error.data)) {
            let errors = new Map<string, FieldError[]>(Object.entries(error.data.payload))
            let fieldErrors = errors.get(field) ?? []
            let messages: string[] = []
            fieldErrors.forEach((fieldError: FieldError) => {
                messages.push(tFieldError(fieldError.code, field))
            })
            return messages
        }
    }
    return []
}

export const ERR_NOT_FOUND = 4001

export function errorMessageIn(error: unknown): string {
    if (isFetchBaseQueryError(error)) {
        if (isBackendError(error.data)) {
            if (error.data.code === ERR_NOT_FOUND && "Entity" in error.data.payload && typeof error.data.payload.Entity == "string") { // EntityNotFound
                return `${t(error.data.payload.Entity)} nicht gefunden.`
            }
            return tError(error.data.code)
        } else {
            if (error.status == 500) {
                return t("Server Fehler")
            }
            // This will be caught by router errorElement and return an error page.
            // See routes/router.tsx or routes/error-pages.tsx for details.
            throw error;
        }
    }
    return ""
}

function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
    return typeof error === 'object' && error != null && 'status' in error
}

function isBackendError(error: any): error is BackendError {
    return error && "type" in error && "code" in error && "codeName" in error
}

function isValidationError(error: any): error is BackendError {
    return isBackendError(error) && error.type === "InputValidation" && "payload" in error
}

export function isEntityNotFound(error: unknown): boolean {
    if (isFetchBaseQueryError(error)) {
        if (isBackendError(error.data)) {
            return error.data.code == ERR_NOT_FOUND
        }
    }
    return false
}
