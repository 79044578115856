import {
    useAssignDefaultCostCenterMutation,
    useRegisterCostCenterMutation,
    useShowCostCenterListQuery,
    useShowCostCenterQuery,
    useShowDefaultCostCenterQuery,
    useShowStoreListQuery,
    useUpdateCostCenterMutation,
} from "../../services/backend/api";
import {Heading2} from "../../shared/components/headings";
import {t} from "../../lang/lang";
import {
    Table,
    TableActions,
    TableBody,
    TableBodyCell,
    TableBodyRow,
    TableBodyRowHeadCell,
    TableHead,
    TableHeadCell,
    TableHeading,
    TableHeadRow
} from "../../shared/components/table";
import {Modal} from "../../shared/components/modal";
import {
    FormCard,
    FormHeading,
    FormSelectInput,
    FormSubmitButton,
    FormTextInput,
    Option
} from "../../shared/components/form";
import {useState} from "react";
import {v4} from "uuid";
import {DangerAlert, SuccessAlert} from "../../shared/components/alerts";
import {errorMessageIn, isEntityNotFound, validationErrorsFor} from "../../services/backend/errors";
import {EditIcon} from "../../shared/components/icons";
import Spinner from "../../shared/components/loading";
import {useActiveCompany} from "../../shared/active-company/active-company-hook";
import {Store} from "../../services/backend/store";
import {CostCenter} from "../../services/backend/cost-center";

export default function CostCentersRoute() {
    const {activeCompanyId} = useActiveCompany()
    if (!activeCompanyId) {
        return <></>
    }

    return (<div className="space-y-6">
        <Heading2>{t("Kostenstellen")}</Heading2>
        <div>
            <p className="font-light text-gray-500 dark:text-gray-400">
                {t("Kostenstellen können Verbräuchen zugeordnet werden und damit " +
                    "als Basis für Auswertungen dienen. Kostenstellen sind typischerweise die Niederlassungen. " +
                    "Es macht also Sinn eine Kostenstelle mit dem Namen \"Niederlassung Augsburg\" zu erstellen. " +
                    "Es können aber beliebige Kostenstellen registriert werden. Kostenstellen sind unabhängig von den " +
                    "Lägern und deren Beständen.")}
            </p>
            <p className="mt-3 font-light text-gray-500 dark:text-gray-400">
                {t("Ein Lager, egal welchen Typs (Fahrzeug / Niederlassung), hat typischerweise eine " +
                    "Standardkostenstelle. Diese dient lediglich der Vereinfachung der Erfassung von Verbräuchen. " +
                    "Sollte also beispielsweise ein Fahrzeug für eine andere Kostenstelle tätig sein, so kann " +
                    "beim Erfassen der Verbräuche diese Kostenstelle ausgewählt werden.")}
            </p>
        </div>
        <CostCenters companyId={activeCompanyId}/>
        <DefaultCostCenters companyId={activeCompanyId}/>
    </div>)
}

function CostCenters(props: { companyId: string }) {
    const {data: costCenters, isSuccess} = useShowCostCenterListQuery({companyId: props.companyId, orderBy: "name asc"})
    if (!isSuccess) {
        return <></>
    }

    return (<div>
        <TableActions>
            <TableHeading text={t("Registrierte Kostenstellen")}/>
            <Modal openIcon={<div
                className="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{t("Neue Kostenstelle")}</div>}>
                <NewCostCenter companyId={props.companyId}/>
            </Modal>
        </TableActions>
        <Table shadow={true}>
            <TableHead>
                <TableHeadRow>
                    <TableHeadCell>{t("Name")}</TableHeadCell>
                    <TableHeadCell>{t("Aktionen")}</TableHeadCell>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {
                    costCenters.map((costCenter, i) => <TableBodyRow key={i}>
                        <TableBodyRowHeadCell>{costCenter.name}</TableBodyRowHeadCell>
                        <TableBodyCell>
                            <Modal openIcon={<EditIcon size={4}/>}><EditCostCenter
                                costCenterId={costCenter.id}/></Modal>
                        </TableBodyCell>
                    </TableBodyRow>)
                }
            </TableBody>
        </Table>
    </div>)
}

function NewCostCenter(props: { companyId: string }) {
    const [name, setName] = useState("")
    const [register, {isSuccess, error, reset}] = useRegisterCostCenterMutation()

    const updateName = (newName: string) => {
        reset()
        setName(newName)
    }

    const canRegister = name.length >= 3

    const onRegister = () => {
        if (canRegister) {
            register({
                id: v4(),
                name: name,
                companyId: props.companyId
            }).unwrap().then(() => setName(""))
        }
    }

    return (<div>
        <FormCard>
            <FormHeading>{t("Neue Kostenstelle registrieren")}</FormHeading>
            <DangerAlert>{errorMessageIn(error)}</DangerAlert>
            <FormTextInput value={name} placeholder={t("Name eingeben")} onChange={(e) => updateName(e.target.value)}
                           errors={validationErrorsFor("name", error)}/>
            <SuccessAlert text={isSuccess ? t("Kostenstelle registriert") : ""}/>
            <FormSubmitButton onClick={onRegister} disabled={!canRegister}>{t("Registrieren")}</FormSubmitButton>
        </FormCard>
    </div>)
}

function EditCostCenter(props: { costCenterId: string }) {
    const {data: costCenter, isSuccess} = useShowCostCenterQuery({id: props.costCenterId})
    const [name, setName] = useState(costCenter?.name ?? "")
    const [update, {isSuccess: updateIsSuccess, error, reset}] = useUpdateCostCenterMutation()
    if (!isSuccess) {
        return <></>
    }

    const updateName = (newName: string) => {
        reset()
        setName(newName)
    }

    const canUpdate = name.length >= 3

    const onUpdate = () => {
        if (canUpdate) {
            update({
                costCenterId: costCenter.id,
                name: name,
            })
        }
    }

    return (<div>
        <FormCard>
            <FormHeading>{t("Kostenstelle bearbeiten")}</FormHeading>
            <DangerAlert>{errorMessageIn(error)}</DangerAlert>
            <FormTextInput value={name ?? costCenter.name} placeholder={t("Name eingeben")}
                           onChange={(e) => updateName(e.target.value)} errors={validationErrorsFor("name", error)}/>
            <SuccessAlert text={updateIsSuccess ? t("Kostenstelle aktualisiert") : ""}/>
            <FormSubmitButton onClick={onUpdate} disabled={!canUpdate}>{t("Aktualisieren")}</FormSubmitButton>
        </FormCard>
    </div>)
}

function DefaultCostCenters(props: { companyId: string }) {
    const {data: stores, isSuccess: storesIsSuccess} = useShowStoreListQuery({
        companyId: props.companyId,
        orderBy: "name asc"
    })
    if (!storesIsSuccess) {
        return <></>
    }

    return (<div>
        <TableActions>
            <TableHeading text={t("Standardkostenstellen")}/>
        </TableActions>
        <Table shadow={true}>
            <TableHead>
                <TableHeadRow>
                    <TableHeadCell>{t("Lager")}</TableHeadCell>
                    <TableHeadCell>{t("Standard Kostenstelle")}</TableHeadCell>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {
                    stores.map((store, i) => <TableBodyRow key={i}>
                        <TableBodyRowHeadCell>{store.name}</TableBodyRowHeadCell>
                        <TableBodyCell>
                            <DefaultCostCenter store={store}/>
                        </TableBodyCell>
                    </TableBodyRow>)
                }
            </TableBody>
        </Table>
    </div>)
}

function DefaultCostCenter(props: { store: Store }) {
    const {
        data: costCenters,
        isSuccess: centersIsSuccess
    } = useShowCostCenterListQuery({companyId: props.store.companyId, orderBy: "name asc"})
    if (!centersIsSuccess) {
        return <></>
    }

    return (<div>
        <DefaultNotCreated storeId={props.store.id}/>
        <DefaultNotSet storeId={props.store.id}/>
        <SelectCostCenter store={props.store} centers={costCenters}/>
    </div>)
}

function DefaultNotSet(props: { storeId: string }) {
    const {data, isSuccess} = useShowDefaultCostCenterQuery({storeId: props.storeId})
    if (!isSuccess) {
        return <></>
    }

    const defaultNotSet = data.costCenterId == "" || data.costCenterId == "00000000-0000-0000-0000-000000000000"

    return (<DangerAlert>{defaultNotSet ? t("Standardkostenstelle nicht gesetzt!") : ""}</DangerAlert>)
}

function DefaultNotCreated(props: { storeId: string }) {
    const {error} = useShowDefaultCostCenterQuery({storeId: props.storeId})
    if (!error) {
        return <></>
    }

    return (<DangerAlert>{isEntityNotFound(error) ? t("Standardkostenstelle nicht gesetzt!") : ""}</DangerAlert>)
}

function SelectCostCenter(props: { store: Store, centers: CostCenter[] }) {
    const {data: defaultCenter, isSuccess, error} = useShowDefaultCostCenterQuery({storeId: props.store.id})
    const [assign, {isLoading: isAssigning, reset}] = useAssignDefaultCostCenterMutation()
    if (!isSuccess && !isEntityNotFound(error)) {
        return <></>
    }
    if (isAssigning) {
        return <Spinner/>
    }

    let selectedValue = defaultCenter ? defaultCenter.costCenterId : ""
    if (selectedValue == "00000000-0000-0000-0000-000000000000") {
        selectedValue = ""
    }

    const options: Option[] = props.centers.map((costCenter) => ({
        value: costCenter.id,
        display: costCenter.name
    }))

    const onUpdate = (costCenterId: string) => {
        reset()
        assign({
            storeId: props.store.id,
            costCenterId: costCenterId,
        })
    }

    return (
        <FormSelectInput
            value={selectedValue}
            options={options}
            onChange={(e) => onUpdate(e.target.value)}
            errors={[]}/>
    )
}