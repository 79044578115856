import {Link, useRouteError} from "react-router-dom";
import {t} from "../lang/lang";
import {useAuth} from "../shared/authentication/provider";

export function errorPageFor(error: any) {
    if (typeof error == "object" && error && "status" in error && error.status == 401) {
        return <ErrorPage401/>
    }

    if (typeof error == "object" && error && "status" in error && error.status == 403) {
        return <ErrorPage403/>
    }

    if (typeof error == "object" && error && "status" in error && error.status == 404) {
        return <ErrorPage404/>
    }

    if (typeof error == "object" && error && "status" in error && error.status == 500) {
        return <ErrorPage500/>
    }

    return <ErrorPageUnknown/>
}

export default function ErrorPage() {
    const error = useRouteError()

    if (process.env.NODE_ENV == "development") {
        console.log(error)
    }

    return errorPageFor(error)
}

function ErrorPageUnknown() {
    const {onLogout} = useAuth()

    return (
        <section className="bg-white dark:bg-gray-900">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">?</h1>
                    <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
                        {t("Unbekannter Fehler")}
                    </p>
                    <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                        {t("Hier ist etwas schief gelaufen. Bitte einfach noch einmal versuchen. Wenn der Fehler bestehen bleibt, bitte an die Entwickler wenden.")}
                    </p>
                    <button onClick={() => window.location.reload()}
                            className="inline-flex text-white bg-gray-500 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">
                        {t("Neu Laden")}
                    </button>
                    <button onClick={() => onLogout()}
                            className="m-4 inline-flex text-white bg-gray-500 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">
                        {t("Logout")}
                    </button>
                </div>
            </div>
        </section>
    )
}

function ErrorPage401() {
    const {onLogout} = useAuth()

    return (
        <section className="bg-white dark:bg-gray-900">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">401</h1>
                    <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
                        {t("Zugriff verweigert")}
                    </p>
                    <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                        {t("Du hast hierauf keinen Zugriff!")}
                    </p>
                    <button onClick={() => onLogout()}
                            className="m-4 inline-flex text-white bg-gray-500 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">
                        {t("Logout")}
                    </button>
                </div>
            </div>
        </section>
    )
}

function ErrorPage403() {
    const {onLogout} = useAuth()

    return (
        <section className="bg-white dark:bg-gray-900">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">403</h1>
                    <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
                        {t("Oops!")}
                    </p>
                    <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                        {t("Du hast hierauf keinen Zugriff!")}
                    </p>
                    <button onClick={() => onLogout()}
                            className="m-4 inline-flex text-white bg-gray-500 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">
                        {t("Logout")}
                    </button>
                </div>
            </div>
        </section>
    )
}

function ErrorPage404() {
    return (
        <section className="bg-white dark:bg-gray-900">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">404</h1>
                    <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
                        {t("Oops!")}
                    </p>
                    <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                        {t("Hier gibt es nichts zu sehen. Am besten einfach zurück zur Startseite:")}
                    </p>
                    <Link to={"/"}
                          className="inline-flex text-white bg-gray-500 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4"
                    >{t("Zurück zur Startseite")}</Link>
                </div>
            </div>
        </section>
    )
}

function ErrorPage500() {
    return (
        <section className="bg-white dark:bg-gray-900">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">500</h1>
                    <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
                        {t("Oops!")}
                    </p>
                    <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                        {t("Hier ist etwas schief gelaufen. Bitte einfach noch einmal versuchen. Wenn der Fehler bestehen bleibt, bitte an die Entwickler wenden.")}
                    </p>
                    <Link to={"/"}
                          className="inline-flex text-white bg-gray-500 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4"
                    >{t("Zurück zur Startseite")}</Link>
                </div>
            </div>
        </section>
    )
}