import {t} from "../../../lang/lang";
import {useShowStoreInventoryQuery} from "../../../services/backend/api";
import {useParams} from "react-router-dom";
import {ChangeEvent, useState} from "react";
import {
    Table,
    TableActions,
    TableActionSearch,
    TableBodyCell,
    TableBodyRowHeadCell,
    TableHead,
    TableHeadCell,
    TableHeadRow
} from "../../../shared/components/table";

export default function StoreInventoryRoute() {
    const {storeId} = useParams()
    const {data: storeInventory, isSuccess} = useShowStoreInventoryQuery({storeId: storeId ?? ""})
    const [filter, setFilter] = useState("")
    if (!isSuccess) {
        return <></>
    }

    let rows: JSX.Element[] = []
    storeInventory.inventory.forEach((item, index) => {
        // filter
        let searchable = item.productName + " " + item.productManufacturerName
        if (filter != "" && searchable.toLowerCase().indexOf(filter.toLowerCase()) === -1) {
            return
        }
        rows.push(
            <tr className={((index + 1) == storeInventory.inventory.length) ? "bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600" : "bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"}
                key={item.productId}>
                <TableBodyRowHeadCell>
                    {item.productName + (item.productManufacturerName != "" ? ", " + item.productManufacturerName : "")}
                </TableBodyRowHeadCell>
                <TableBodyCell>
                    {item.count}
                </TableBodyCell>
                <TableBodyCell>
                    {t(item.measure)}
                </TableBodyCell>
            </tr>
        )
    })

    return (
        <div className="mt-1">
            <TableActions>
                <TableActionSearch value={filter}
                                   onChange={(e: ChangeEvent<HTMLInputElement>) => setFilter(e.target.value)}
                                   placeholder={t("Tabelle durchsuchen")}/>
            </TableActions>
            <Table>
                <TableHead>
                    <TableHeadRow>
                        <TableHeadCell>
                            {t("Produkt")}
                        </TableHeadCell>
                        <TableHeadCell>
                            {t("Anzahl")}
                        </TableHeadCell>
                        <TableHeadCell>
                            {t("Einheit")}
                        </TableHeadCell>
                    </TableHeadRow>
                </TableHead>
                <tbody>
                {storeInventory.inventory.length > 0
                    ? rows.length > 0
                        ? rows
                        : <tr>
                            <td colSpan={3} className="text-center">{t("Nicht gefunden")}</td>
                        </tr>
                    : <tr>
                        <td colSpan={3} className="text-center">{t("Das Lager ist leer")}</td>
                    </tr>}
                </tbody>
            </Table>
        </div>
    )
}