export function WarehouseIcon() {
    return (
        <svg className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
             fill="currentColor" stroke="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 42V14l20-8 20 8v28H32V26H16v16Zm14.5 0v-3h3v3Zm4-6v-3h3v3Zm4 6v-3h3v3Z"/>
        </svg>
    )
}

export function VehicleIcon() {
    return (
        <svg className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
             fill="currentColor" stroke="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.2 39.95q-2.45 0-4.175-1.725Q5.3 36.5 5.3 34.05H2V11q0-1.2.9-2.1Q3.8 8 5 8h28.95v8.35h5.25L46 25.4v8.65h-3.55q0 2.45-1.725 4.175Q39 39.95 36.55 39.95q-2.45 0-4.175-1.725Q30.65 36.5 30.65 34.05H17.1q0 2.45-1.725 4.175Q13.65 39.95 11.2 39.95Zm0-3q1.2 0 2.05-.85.85-.85.85-2.05 0-1.2-.85-2.05-.85-.85-2.05-.85-1.2 0-2.05.85-.85.85-.85 2.05 0 1.2.85 2.05.85.85 2.05.85Zm25.35 0q1.2 0 2.05-.85.85-.85.85-2.05 0-1.2-.85-2.05-.85-.85-2.05-.85-1.2 0-2.05.85-.85.85-.85 2.05 0 1.2.85 2.05.85.85 2.05.85Zm-2.6-10.2h9.3l-5.55-7.4h-3.75Z"/>
        </svg>
    )
}

export function EditIcon(props: {size?: number}) {
    let size = 6
    if (props.size) {
        size = props.size
    }
    return (
        <svg className={`w-${size} h-${size} text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white`}
             fill="currentColor" stroke="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 39h2.2l22.15-22.15-2.2-2.2L9 36.8Zm30.7-24.3-6.4-6.4 2.1-2.1q.85-.85 2.1-.85t2.1.85l2.2 2.2q.85.85.85 2.1t-.85 2.1Zm-2.1 2.1L12.4 42H6v-6.4l25.2-25.2Zm-5.35-1.05-1.1-1.1 2.2 2.2Z"/>
        </svg>
    )
}

export function DeleteIcon(props: {size?: number}) {
    let size = 6
    if (props.size) {
        size = props.size
    }
    return (
        <svg className={`w-${size} h-${size} text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white`}
             fill="currentColor" stroke="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.05 42q-1.2 0-2.1-.9-.9-.9-.9-2.1V10.5H8v-3h9.4V6h13.2v1.5H40v3h-2.05V39q0 1.2-.9 2.1-.9.9-2.1.9Zm5.3-7.3h3V14.75h-3Zm8.3 0h3V14.75h-3Z"/>
        </svg>
    )
}

export function ReceiptIcon(props: {size?: number}) {
    let size = 6
    if (props.size) {
        size = props.size
    }
    return (
        <svg className={`w-${size} h-${size} text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white`}
             fill="currentColor" stroke="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 42q-1.25 0-2.125-.875T6 39V9q0-1.25.875-2.125T9 6h10.25q.25-1.75 1.6-2.875T24 2q1.8 0 3.15 1.125Q28.5 4.25 28.75 6H39q1.25 0 2.125.875T42 9v30q0 1.25-.875 2.125T39 42Zm5-8h13.65v-3H14Zm0-8.5h20v-3H14Zm0-8.5h20v-3H14Zm10-8.85q.7 0 1.225-.525.525-.525.525-1.225 0-.7-.525-1.225Q24.7 4.65 24 4.65q-.7 0-1.225.525-.525.525-.525 1.225 0 .7.525 1.225.525.525 1.225.525Z"/>
        </svg>
    )
}
export function TransferIcon(props: {size?: number}) {
    let size = 6
    if (props.size) {
        size = props.size
    }
    return (
        <svg className={`w-${size} h-${size} text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white`}
             fill="currentColor" stroke="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="m6.25 46 5.7-29.3-4.9 2.15v6.7h-3v-8.7l9.4-4.05q1.35-.6 2.925-.325 1.575.275 2.375 1.575l2.1 3.3q1.4 2.25 3.575 3.725Q26.6 22.55 29.45 22.55v3q-3 0-5.75-1.4t-4.85-4.55l-1.95 7.65 4.45 4.15V46h-3V34.05l-5.2-4.95L9.35 46Zm12.5-35.7q-1.5 0-2.575-1.075Q15.1 8.15 15.1 6.65q0-1.5 1.075-2.575Q17.25 3 18.75 3q1.5 0 2.575 1.075Q22.4 5.15 22.4 6.65q0 1.5-1.075 2.575Q20.25 10.3 18.75 10.3Zm20.5 35.65L37.5 44.2l1.75-1.75H28v-2.5h11.25L37.5 38.2l1.75-1.75L44 41.2Zm-6.5-8.8L28 32.4l4.75-4.75 1.75 1.75-1.75 1.75H44v2.5H32.75l1.75 1.75Z"/>
        </svg>
    )
}

export function ConsumptionIcon(props: {size?: number}) {
    let size = 6
    if (props.size) {
        size = props.size
    }
    return (
        <svg className={`w-${size} h-${size} text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white`}
             fill="currentColor" stroke="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M38.4 42 25.85 29.45l2.85-2.85 12.55 12.55ZM9.35 42 6.5 39.15 21 24.65l-5.35-5.35-1.15 1.15-2.2-2.2v4.25l-1.2 1.2L5 17.6l1.2-1.2h4.3L8.1 14l6.55-6.55q.85-.85 1.85-1.15 1-.3 2.2-.3 1.2 0 2.2.425 1 .425 1.85 1.275l-5.35 5.35 2.4 2.4-1.2 1.2 5.2 5.2 6.1-6.1q-.4-.65-.625-1.5-.225-.85-.225-1.8 0-2.65 1.925-4.575Q32.9 5.95 35.55 5.95q.75 0 1.275.15.525.15.875.4l-4.25 4.25 3.75 3.75 4.25-4.25q.25.4.425.975t.175 1.325q0 2.65-1.925 4.575Q38.2 19.05 35.55 19.05q-.9 0-1.55-.125t-1.2-.375Z"/>
        </svg>
    )
}

export function CostCenterIcon(props: {size?: number}) {
    let size = 6
    if (props.size) {
        size = props.size
    }
    return (
        <svg className={`w-${size} h-${size} text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white`}
             fill="currentColor" stroke="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 44q-1.2 0-2.1-.9Q8 42.2 8 41V7q0-1.2.9-2.1Q9.8 4 11 4h18.05L40 14.95V41q0 1.2-.9 2.1-.9.9-2.1.9Zm11.5-6.05h3v-2h3q.65 0 1.075-.425Q30 35.1 30 34.45v-6.5q0-.65-.425-1.075-.425-.425-1.075-.425H21v-3.5h9v-3h-4.5v-2h-3v2h-3q-.65 0-1.075.425Q18 20.8 18 21.45v6.5q0 .65.425 1.075.425.425 1.075.425H27v3.5h-9v3h4.5Zm5.3-23h7.95L27.8 7Z"/>
        </svg>
    )
}

export function CompanyIcon(props: {size?: number}) {
    let size = 6
    if (props.size) {
        size = props.size
    }
    return (
        <svg className={`w-${size} h-${size} transition duration-75 group-hover:text-gray-900 dark:group-hover:text-white`}
             fill="currentColor" stroke="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 42V14.25h8.25V6h19.5v16.5H42V42H26.65v-8.25h-5.3V42Zm3-3h5.25v-5.25H9Zm0-8.25h5.25V25.5H9Zm0-8.25h5.25v-5.25H9Zm8.25 8.25h5.25V25.5h-5.25Zm0-8.25h5.25v-5.25h-5.25Zm0-8.25h5.25V9h-5.25Zm8.25 16.5h5.25V25.5H25.5Zm0-8.25h5.25v-5.25H25.5Zm0-8.25h5.25V9H25.5ZM33.75 39H39v-5.25h-5.25Zm0-8.25H39V25.5h-5.25Z"/>
        </svg>
    )
}

export function PrinterIcon(props: {size?: number}) {
    let size = 6
    if (props.size) {
        size = props.size
    }
    return (
        <svg className={`w-${size} h-${size} transition duration-75 group-hover:text-gray-900 dark:group-hover:text-white`}
             fill="currentColor" stroke="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.9 14.1H12.1V6h23.8Zm1.05 9.25q.6 0 1.05-.45.45-.45.45-1.05 0-.6-.45-1.05-.45-.45-1.05-.45-.6 0-1.05.45-.45.45-.45 1.05 0 .6.45 1.05.45.45 1.05.45ZM32.9 39v-9.6H15.1V39Zm3 3H12.1v-8.8H4V20.9q0-2.25 1.525-3.775T9.3 15.6h29.4q2.25 0 3.775 1.525T44 20.9v12.3h-8.1Z"/>
        </svg>
    )
}

export function LogoutIcon(props: {size?: number}) {
    let size = 6
    if (props.size) {
        size = props.size
    }
    return (
        <svg className={`w-${size} h-${size} transition duration-75 group-hover:text-gray-900 dark:group-hover:text-white`}
             fill="currentColor" stroke="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h14.55v3H9v30h14.55v3Zm24.3-9.25-2.15-2.15 5.1-5.1h-17.5v-3h17.4l-5.1-5.1 2.15-2.15 8.8 8.8Z"/>
        </svg>
    )
}


export function NotificationIcon(props: {size?: number}) {
    let size = 6
    if (props.size) {
        size = props.size
    }
    return (
        <svg className={`w-${size} h-${size} transition duration-75 group-hover:text-gray-900 dark:group-hover:text-white`}
             fill="currentColor" stroke="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 38v-3h4.2V19.7q0-4.2 2.475-7.475Q17.15 8.95 21.2 8.1V6.65q0-1.15.825-1.9T24 4q1.15 0 1.975.75.825.75.825 1.9V8.1q4.05.85 6.55 4.125t2.5 7.475V35H40v3Zm16 6q-1.6 0-2.8-1.175Q20 41.65 20 40h8q0 1.65-1.175 2.825Q25.65 44 24 44Z"/>
        </svg>
    )
}


