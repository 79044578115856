import {createBrowserRouter} from "react-router-dom";
import Root from "./root";
import {MsalAuthenticationTemplate} from "@azure/msal-react";
import {InteractionType} from "@azure/msal-browser";
import {AuthContextProviderMsal} from "../shared/authentication/msal";
import ErrorPage from "./error-pages";
import CostCentersRoute from "./cost-centers/cost-centers";
import CompaniesRoute from "./companies/companies";
import StoresRoute from "./stores/stores";
import ReportingRoute from "./reporting/reporting";
import QrCodesStores from "./qr-codes/qr-codes-stores";
import QrCodesProducts from "./qr-codes/qr-codes-products";
import QrCodesRoute from "./qr-codes/qr-codes";
import RequestedProducts from "./products/requested-products";
import ProductList from "./products/product-list";
import ProductsRoute from "./products/products";
import ProductInventoryRoute from "./inventories/products/product-inventory";
import LedgerRoute from "./inventories/stores/store-ledger";
import StoreInventoryRoute from "./inventories/stores/store-inventory";
import StoreRoute from "./inventories/stores/store";
import InventoriesRoute from "./inventories/inventories";
import ProductRoute from "./inventories/products/product";
import CostCenterConsumptionRoute from "./reporting/cost-center-consumption/cost-center-consumption";
import ActiveCompany from "../shared/active-company/active-company";
import {RoleGuard} from "../shared/authentication/role-guard";
import NotificationSettingsRoute from "./notification-settings/notification-settings";
import {ADMINISTRATOR, BRANCHMANAGER, DATAMANAGER, DIRECTOR} from "../services/backend/user";

export const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <AuthContextProviderMsal>
                    <ActiveCompany>
                        <Root/>
                    </ActiveCompany>
                </AuthContextProviderMsal>
            </MsalAuthenticationTemplate>
        ),
        errorElement: <ErrorPage/>,
        children: [
            {
                path: "inventories",
                element: <InventoriesRoute/>,
                children: [
                    {
                        path: "stores/:storeId",
                        element: <StoreRoute/>,
                        children: [
                            {
                                path: "inventory",
                                element: <StoreInventoryRoute/>,
                            },
                            {
                                path: "ledger",
                                element: <LedgerRoute/>,
                            },
                        ],
                    },
                    {
                        path: "products/:productId",
                        element: <ProductRoute/>,
                        children: [
                            {
                                path: "inventory",
                                element: <ProductInventoryRoute/>,
                            }
                        ],
                    }
                ],
            },
            {
                path: "/products",
                element: <RoleGuard
                    oneOfRoles={[ADMINISTRATOR, DATAMANAGER, DIRECTOR]}><ProductsRoute/></RoleGuard>,
                children: [
                    {
                        path: "list",
                        element: <ProductList/>
                    },
                    {
                        path: "requested",
                        element: <RequestedProducts/>
                    },
                ],
            },
            {
                path: "/qr-codes",
                element: <RoleGuard
                    oneOfRoles={[ADMINISTRATOR, DATAMANAGER, DIRECTOR, BRANCHMANAGER]}><QrCodesRoute/></RoleGuard>,
                children: [
                    {
                        path: "products",
                        element: <QrCodesProducts/>
                    },
                    {
                        path: "stores",
                        element: <QrCodesStores/>
                    },
                ],
            },
            {
                path: "/reporting",
                element: <RoleGuard oneOfRoles={[ADMINISTRATOR, DATAMANAGER, DIRECTOR, BRANCHMANAGER]}><ReportingRoute/></RoleGuard>,
            },
            {
                path: "/stores",
                element: <RoleGuard oneOfRoles={[ADMINISTRATOR, DATAMANAGER, DIRECTOR]}><StoresRoute/></RoleGuard>,
            },
            {
                path: "/companies",
                element: <RoleGuard oneOfRoles={[ADMINISTRATOR]}><CompaniesRoute/></RoleGuard>,
            },
            {
                path: "/cost-centers",
                element: <RoleGuard oneOfRoles={[ADMINISTRATOR, DATAMANAGER, DIRECTOR]}><CostCentersRoute/></RoleGuard>,
            },
            {
                path: "/settings/notifications",
                element: <NotificationSettingsRoute/>,
            }
        ]
    },
    {
        path: "/reporting/cost-center-consumption/:costCenterId/:from/:to",
        element: <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            <AuthContextProviderMsal>
                <CostCenterConsumptionRoute/></AuthContextProviderMsal>
        </MsalAuthenticationTemplate>,
    },
])
