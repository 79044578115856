import {useShowUserQuery} from "../../services/msgraph/msgraph";

export default function Username(props: { id: string }) {
    const {data: user, isLoading, isError} = useShowUserQuery({
        id: props.id
    })

    if (isLoading) {
        return <></>
    }

    if (isError) {
        return <>{props.id}</>
    }

    return <>{user!.displayName}</>
}