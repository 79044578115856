import {createContext, useContext} from "react";
import {User} from "../../services/backend/user";

export const useAuth = () => {
    return useContext(AuthContext)
}

export interface AuthContextInterface {
    user: User | null
    onLogout: () => void
}

export const AuthContext = createContext<AuthContextInterface>({
    user: null,
    onLogout: () => null,
})
