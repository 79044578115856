export interface User {
    id: string
    name: string
    roles: string[]
}

export function userHasRole(user: User, role: string): boolean {
    for (const r of user.roles) {
        if (r == role) {
            return true
        }
    }
    return false
}

export function initials(name: string): string {
    return name.split(" ").reduce((previousValue, currentValue) => previousValue + currentValue.charAt(0).toUpperCase(), "");
}

export const TECHNICIAN = "Technician"
export const BRANCHMANAGER = "BranchManager"
export const DATAMANAGER = "DataManager"
export const DIRECTOR = "Director"
export const ADMINISTRATOR = "Administrator"