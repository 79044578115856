import {Heading2} from "../../shared/components/headings";
import {t} from "../../lang/lang";
import {FormCard, FormDateInput, FormHeading, FormSelectInput, FormSubmitButton} from "../../shared/components/form";
import {useState} from "react";
import {useShowCostCenterListQuery} from "../../services/backend/api";
import {Link} from "react-router-dom";
import UnconfirmedProductsAlert from "../../shared/unconfirmed-products-alert";
import {useActiveCompany} from "../../shared/active-company/active-company-hook";

export default function ReportingRoute() {
    return (<div className="space-y-6">
        <Heading2>{t("Reporting")}</Heading2>
        <CostCenterConsumptionRequest/>
    </div>)
}


function CostCenterConsumptionRequest() {
    const {activeCompanyId} = useActiveCompany()

    const {data: centers, isSuccess} = useShowCostCenterListQuery({
        companyId: activeCompanyId ?? "",
        orderBy: "name asc",
    })
    const [costCenterId, setCostCenterId] = useState("")
    const [from, setFrom] = useState("")
    const [to, setTo] = useState("")

    if (!isSuccess) {
        return <></>
    }

    const fromIsBeforeTo = inputToDate(from).getTime() < inputToDate(to).getTime()
    const canRequest = costCenterId != "" && from != "" && to != "" && fromIsBeforeTo

    return (<div>
        <FormCard>
            <UnconfirmedProductsAlert/>
            <FormHeading>{t("Kostenstellen Bericht anfordern")}</FormHeading>
            <FormSelectInput
                label={t("Kostenstelle")}
                value={costCenterId}
                options={centers.map(c => ({value: c.id, display: c.name}))}
                onChange={(e) => setCostCenterId(e.target.value)}
                errors={[]}/>
            <div className="flex items-center space-x-6">
                <FormDateInput value={from} label={t("Von")} onChange={(newDay) => setFrom(newDay)}/>
                <FormDateInput value={to} label={t("Bis")} onChange={(newDay) => setTo(newDay)}/>
            </div>
            {canRequest
                ? <div><Link
                    to={`/reporting/cost-center-consumption/${costCenterId}/${from}/${to}`}
                    target={"_blank"}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >{t("Anfordern")}</Link></div>
                : <FormSubmitButton onClick={() => null} disabled={true}>{t("Anfordern")}</FormSubmitButton>
            }
        </FormCard>
    </div>)
}

function inputToDate(string: string): Date {
    const [year, month, day] = string.split("-")
    return new Date(Number(year), Number(month), Number(day))
}


