
export function Button(props: { text: string, onClick: () => void, type?: string, disabled?: boolean }) {
    if (props.disabled == true) {
        return (
            <button type="button"
                    className="text-white bg-blue-400 dark:bg-blue-500 cursor-not-allowed font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    disabled>{props.text}</button>
        )
    }

    return (
        <button type="button"
                onClick={props.onClick}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            {props.text}
        </button>
    )
}