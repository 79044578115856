import {t} from "../../lang/lang";
import {VehicleIcon, WarehouseIcon} from "../../shared/components/icons";

export interface ShowStoreRequest {
    storeId: string
}

export interface ShowStoreListRequest {
    companyId?: string
    type?: string
    limit?: number
    orderBy?: string
}

export interface RegisterStoreRequest {
    id: string
    type: string
    name: string
    companyId: string
}

export interface UpdateStoreRequest {
    id: string
    type: string
    name: string
}

export interface DeleteStoreRequest {
    storeId: string
}

export interface Store {
    id: string
    type: string
    name: string
    companyId: string
    companyName: string
}

export const storeTypes = [
    {
        value: "warehouse",
        display: t("warehouse"),
    },
    {
        value: "vehicle",
        display: t("vehicle"),
    },
]

export function logoForStore(type: string) {
    switch (type) {
        case "vehicle":
            return VehicleIcon()
        case "warehouse":
            return WarehouseIcon()
        default:
            return <></>
    }
}
