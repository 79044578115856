import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {
    DeleteStoreRequest,
    RegisterStoreRequest,
    ShowStoreListRequest,
    ShowStoreRequest,
    Store,
    UpdateStoreRequest
} from "./store";
import {
    ActivateProductRequest,
    ConfirmProductRequest,
    DeactivateProductRequest,
    NewProductRequest,
    Product,
    ReplaceProductRequest,
    ShowProductListRequest,
    ShowProductRequest,
    UpdateUnconfirmedProductRequest
} from "./product";
import {
    CostCenterConsumption,
    EntriesPage,
    ProductInventory,
    ShowCostCenterConsumptionRequest,
    ShowLedgerRequest,
    ShowProductInventoryRequest,
    ShowStoreInventoryRequest,
    StoreInventory
} from "./ledger";
import {
    AcknowledgePortalNotificationRequest,
    EmailAddress,
    PortalNotification,
    ShowEmailAddressRequest,
    ShowPortalNotificationsRequest,
    ShowSubscriptionListRequest,
    Subscription,
    UpdateEmailAddressRequest,
    UpdateSubscriptionRequest
} from "./notification";
import {
    Company,
    RegisterCompanyRequest,
    ShowCompanyListRequest,
    ShowCompanyRequest,
    UpdateCompanyRequest
} from "./company";
import {
    AssignDefaultCostCenter,
    CostCenter,
    DefaultCostCenter,
    RegisterCostCenterRequest,
    ShowCostCenterListRequest,
    ShowCostCenterRequest,
    ShowDefaultCostCenterRequest,
    UpdateCostCenterRequest
} from "./cost-center";
import {RootState, store} from "../../store";
import {InteractionRequiredAuthError, PublicClientApplication} from "@azure/msal-browser";
import {loginRequest, msalConfig} from "../../shared/authentication/msal";
import {set} from "../../shared/authentication/accessTokenSlice";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {AzureGroup, DeleteAzureGroupRequest, ShowAzureGroupListRequest, UpdateAzureGroupRequest} from "./azure";

export const backendApi = createApi({
    reducerPath: 'backendApi',
    baseQuery: reAuthOn401Wrapper(fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BACKEND_URL,
        prepareHeaders: async (headers, api) => {
            // If there is an access token saved in memory it should be used
            // and no new token should be acquired.
            // Unfortunately, the MSAL library does not reuse the access token
            // saves in the session storage when calling acquireTokenSilent.
            // If this was the case, manually saving the access token was not needed.
            // Should the token be expired, the backend will return a 401 and a
            // re-authentication will be triggered.
            const token = (api.getState() as RootState).accessToken.value
            if (token != null) {
                headers.set("Authorization", `Bearer ${token}`)
                return headers
            }

            // This will attempt to acquire a new access token, using the refresh token
            // if it exists.
            const pca = new PublicClientApplication(msalConfig)
            const account = pca.getAllAccounts()[0]
            if (!account) {
                pca.loginRedirect(loginRequest).catch(e => console.log(e))
            }
            try {
                let response = await pca.acquireTokenSilent({...loginRequest, account});
                store.dispatch(set(response.accessToken))
                headers.set("Authorization", `Bearer ${response.accessToken}`)
                return headers
            } catch (e) {
                // This is needed in case the refresh token is expired or no session data is set
                if (e instanceof InteractionRequiredAuthError) {
                    pca.acquireTokenRedirect({...loginRequest, account}).catch(e => console.log(e))
                }
            }
        },
    })),
    tagTypes: ["AzureGroups", "CompanyInventory", "CostCenters", "DefaultCostCenters", "Email", "Companies", "Entries", "Ledger", "Notifications", "Products", "ProductInventory", "Stores", "StoreInventory", "Subscriptions", "Usernames"],
    endpoints: (build) => ({

        // LEDGER
        showProductInventory: build.query<ProductInventory, ShowProductInventoryRequest>({
            query: (request: ShowProductInventoryRequest) => ({
                url: `ledger/product-inventory`,
                method: "GET",
                params: request,
            }),
            providesTags: result => result
                ? [{type: "ProductInventory", id: result.product.id}]
                : [{type: "ProductInventory", id: "LIST"}]
        }),
        showStoreInventory: build.query<StoreInventory, ShowStoreInventoryRequest>({
            query: (request: ShowStoreInventoryRequest) => ({
                url: `ledger/store-inventory`,
                method: "GET",
                params: request,
            }),
            providesTags: result => result
                ? [{type: "StoreInventory", id: result.store.id}]
                : [{type: "StoreInventory", id: "LIST"}]
        }),
        showEntryList: build.query<EntriesPage, ShowLedgerRequest>({
            query: (request: ShowLedgerRequest) => ({
                url: `ledger`,
                method: "GET",
                params: request,
            }),
            providesTags: [{type: 'Entries', id: 'PARTIAL-LIST'}],
        }),
        showCostCenterConsumption: build.query<CostCenterConsumption, ShowCostCenterConsumptionRequest>({
            query: (request) => ({
                url: `ledger/cost-center-consumption`,
                method: "GET",
                params: request,
            }),
        }),

        // COST CENTER
        showCostCenter: build.query<CostCenter, ShowCostCenterRequest>({
            query: (request) => ({
                url: `cost-center`,
                method: "GET",
                params: request,
            }),
            providesTags: (result) => result ? [{type: 'CostCenters', id: result.id}] : []
        }),
        showCostCenterList: build.query<CostCenter[], ShowCostCenterListRequest>({
            query: (request) => ({
                url: `cost-center/list`,
                method: "GET",
                params: request,
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}) => ({type: 'CostCenters' as const, id: id})),
                        {type: 'CostCenters', id: 'LIST'}
                    ]
                    : [{type: 'CostCenters', id: 'LIST'}],
        }),
        registerCostCenter: build.mutation<CostCenter, RegisterCostCenterRequest>({
            query: (request) => ({
                url: `cost-center`,
                method: "POST",
                body: request
            }),
            invalidatesTags: [{type: 'CostCenters', id: 'LIST'}],
        }),
        updateCostCenter: build.mutation<CostCenter, UpdateCostCenterRequest>({
            query: (request) => ({
                url: `cost-center`,
                method: "PATCH",
                body: request,
            }),
            invalidatesTags: (result) => result ? [{type: 'CostCenters', id: result.id}] : []
        }),
        showDefaultCostCenter: build.query<DefaultCostCenter, ShowDefaultCostCenterRequest>({
            query: (request) => ({
                url: `cost-center/default`,
                method: "GET",
                params: request,
            }),
            providesTags: (result) => result ? [{type: 'DefaultCostCenters', id: result.storeId}] : []
        }),
        assignDefaultCostCenter: build.mutation<DefaultCostCenter, AssignDefaultCostCenter>({
            query: (request) => ({
                url: `cost-center/default`,
                method: "PUT",
                body: request,
            }),
            invalidatesTags: (result) =>
                result
                    ? [{type: 'DefaultCostCenters', id: result.storeId}]
                    : [{type: 'DefaultCostCenters', id: 'LIST'}]
        }),

        // COMPANY
        showCompany: build.query<Company, ShowCompanyRequest>({
            query: (request) => ({
                url: `company`,
                method: "GET",
                params: request,
            }),
            providesTags: (result) => result ? [{type: 'Companies', id: result.id}] : []
        }),
        showCompanyList: build.query<Company[], ShowCompanyListRequest>({
            query: (request) => ({
                url: `company/list`,
                method: "GET",
                params: request,
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}) => ({type: 'Companies' as const, id})),
                        {type: 'Companies', id: 'LIST'},
                    ]
                    : [{type: 'Companies', id: 'LIST'}],
        }),
        registerCompany: build.mutation<Company, RegisterCompanyRequest>({
            query: (request) => ({
                url: `company`,
                method: "POST",
                body: request,
            }),
            invalidatesTags: [{type: 'Companies', id: 'LIST'}],
        }),
        updateCompany: build.mutation<Company, UpdateCompanyRequest>({
            query: (request) => ({
                url: `company`,
                method: "PATCH",
                body: request,
            }),
            invalidatesTags: (result) => result
                ? [{type: 'Companies', id: result.id}]
                : [{type: 'Companies', id: 'LIST'}],
        }),

        // PRODUCT
        showProduct: build.query<Product, ShowProductRequest>({
            query: (request: ShowProductRequest) => ({
                url: `product`,
                method: "GET",
                params: request,
            }),
            providesTags: result => result
                ? [{type: "Products" as const, id: result.id}]
                : []
        }),
        requestNewProduct: build.mutation<Product, NewProductRequest>({
            query: (request: NewProductRequest) => ({
                url: `product`,
                method: "POST",
                body: request,
            }),
            invalidatesTags: (result) => result
                ? [{type: 'Products', id: result.id}, {type: 'Products', id: 'LIST'}]
                : [{type: 'Products', id: 'LIST'}],
        }),
        showProductList: build.query<Product[], ShowProductListRequest>({
            query: (request) => ({
                url: `product/list`,
                method: "GET",
                params: request,
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}) => ({type: 'Products' as const, id})),
                        {type: 'Products', id: 'LIST'},
                    ]
                    : [{type: 'Products', id: 'LIST'}],
        }),
        updateProduct: build.mutation<Product, UpdateUnconfirmedProductRequest>({
            query: (request) => ({
                url: `product/update-unconfirmed`,
                method: "PATCH",
                body: request,
            }),
            invalidatesTags: (result) => result
                ? [{type: 'Products', id: result.id}]
                : [{type: 'Products', id: 'LIST'}],
        }),
        replaceProduct: build.mutation<void, ReplaceProductRequest>({
            query: (request) => ({
                url: `product/replace`,
                method: "DELETE",
                body: request,
            }),
            invalidatesTags: [{type: 'Products', id: 'LIST'}],
        }),
        confirmProduct: build.mutation<Product, ConfirmProductRequest>({
            query: (request) => ({
                url: `product/confirm`,
                method: "PATCH",
                body: request,
            }),
            invalidatesTags: (result) => result
                ? [{type: 'Products', id: result.id}, {type: 'Products', id: 'LIST'}]
                : [{type: 'Products', id: 'LIST'}],
        }),
        activateProduct: build.mutation<Product, ActivateProductRequest>({
            query: (request) => ({
                url: `product/activate`,
                method: "PATCH",
                body: request,
            }),
            invalidatesTags: (result) => result
                ? [{type: 'Products', id: result.id}]
                : [{type: 'Products', id: 'LIST'}],
        }),
        deactivateProduct: build.mutation<Product, DeactivateProductRequest>({
            query: (request) => ({
                url: `product/deactivate`,
                method: "PATCH",
                body: request,
            }),
            invalidatesTags: (result) => result
                ? [{type: 'Products', id: result.id}]
                : [{type: 'Products', id: 'LIST'}],
        }),

        // STORE
        showStore: build.query<Store, ShowStoreRequest>({
            query: (request: ShowStoreRequest) => ({
                url: `store`,
                method: "GET",
                params: request,
            }),
        }),
        showStoreList: build.query<Store[], ShowStoreListRequest>({
            query: (request) => ({
                url: `store/list`,
                method: "GET",
                params: request,
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}) => ({type: 'Stores' as const, id})),
                        {type: 'Stores', id: 'LIST'},
                    ]
                    : [{type: 'Stores', id: 'LIST'}],
        }),
        registerStore: build.mutation<Store, RegisterStoreRequest>({
            query: (request: RegisterStoreRequest) => ({
                url: `store`,
                method: "POST",
                body: request,
            }),
            invalidatesTags: [{type: 'Stores', id: 'LIST'}],
        }),
        updateStore: build.mutation<Store, UpdateStoreRequest>({
            query: (request: UpdateStoreRequest) => ({
                url: `store`,
                method: "PATCH",
                body: request
            }),
            invalidatesTags: (result) => result
                ? [{type: 'Stores', id: result.id}]
                : [{type: 'Stores', id: 'LIST'}],
        }),
        deleteStore: build.mutation<Store, DeleteStoreRequest>({
            query: (request: DeleteStoreRequest) => ({
                url: `store`,
                method: "DELETE",
                body: request,
            }),
            invalidatesTags: (result) => result
                ? [{type: 'Stores', id: result.id}, {type: 'Stores', id: 'LIST'}]
                : [{type: 'Stores', id: 'LIST'}],
        }),

        // NOTIFICATION
        showSubscriptionList: build.query<Subscription[], ShowSubscriptionListRequest>({
            query: (request) => ({
                url: `notification/subscription/list`,
                method: "GET",
                params: request,
            }),
            providesTags: [{type: 'Subscriptions', id: 'LIST'}],
        }),
        updateSubscription: build.mutation<Subscription, UpdateSubscriptionRequest>({
            query: (request) => ({
                url: `notification/subscription`,
                method: "PUT",
                body: request,
            }),
            invalidatesTags: [{type: 'Subscriptions', id: 'LIST'}],
        }),
        showEmailAddress: build.query<EmailAddress, ShowEmailAddressRequest>({
            query: (request) => ({
                url: `notification/email-address`,
                method: "GET",
                params: request,
            }),
            providesTags: [{type: 'Email', id: 'LIST'}],
        }),
        updateEmailAddress: build.mutation<EmailAddress, UpdateEmailAddressRequest>({
            query: (request) => ({
                url: `notification/email-address`,
                method: "PUT",
                body: request,
            }),
            invalidatesTags: [{type: 'Email', id: 'LIST'}],
        }),
        showPortalNotifications: build.query<PortalNotification[], ShowPortalNotificationsRequest>({
            query: (request) => ({
                url: `notification/for-user`,
                method: "GET",
                params: request,
            }),
            providesTags: [{type: 'Notifications', id: 'LIST'}],
        }),
        acknowledgePortalNotification: build.mutation<PortalNotification, AcknowledgePortalNotificationRequest>({
            query: (request) => ({
                url: `notification/acknowledge`,
                method: "PATCH",
                body: request,
            }),
            invalidatesTags: [{type: 'Notifications', id: 'LIST'}],
        }),

        // Azure
        showAzureGroupList: build.query<AzureGroup[], ShowAzureGroupListRequest>({
            query: (request) => ({
                url: `azure-group/list`,
                method: "GET",
                params: request,
            }),
            providesTags: [{type: 'AzureGroups', id: 'LIST'}],
        }),
        updateAzureGroup: build.mutation<AzureGroup, UpdateAzureGroupRequest>({
            query: (request) => ({
                url: `azure-group`,
                method: "PUT",
                body: request,
            }),
            invalidatesTags: [{type: 'AzureGroups', id: 'LIST'}],
        }),
        deleteAzureGroup: build.mutation<void, DeleteAzureGroupRequest>({
            query: (request) => ({
                url: `azure-group`,
                method: "DELETE",
                body: request,
            }),
            invalidatesTags: [{type: 'AzureGroups', id: 'LIST'}],
        }),
        showUserCompanies: build.query<string[], void>({
            query: (request) => ({
                url: `azure-group/companies`,
                method: "GET",
                params: request
            })
        }),
    })
})

export const {
    useShowEntryListQuery,
    useShowProductInventoryQuery,
    useShowStoreInventoryQuery,
    useShowCostCenterConsumptionQuery,
    useShowCostCenterQuery,
    useShowCostCenterListQuery,
    useRegisterCostCenterMutation,
    useUpdateCostCenterMutation,
    useShowDefaultCostCenterQuery,
    useAssignDefaultCostCenterMutation,
    useShowCompanyQuery,
    useShowCompanyListQuery,
    useRegisterCompanyMutation,
    useUpdateCompanyMutation,
    useShowProductQuery,
    useRequestNewProductMutation,
    useShowProductListQuery,
    useUpdateProductMutation,
    useReplaceProductMutation,
    useConfirmProductMutation,
    useActivateProductMutation,
    useDeactivateProductMutation,
    useShowStoreQuery,
    useShowStoreListQuery,
    useRegisterStoreMutation,
    useUpdateStoreMutation,
    useDeleteStoreMutation,
    useShowSubscriptionListQuery,
    useUpdateSubscriptionMutation,
    useShowEmailAddressQuery,
    useUpdateEmailAddressMutation,
    useAcknowledgePortalNotificationMutation,
    useShowPortalNotificationsQuery,
    useShowAzureGroupListQuery,
    useUpdateAzureGroupMutation,
    useDeleteAzureGroupMutation,
    useShowUserCompaniesQuery,
} = backendApi

// This wrapper was designed based on:
// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
function reAuthOn401Wrapper(baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>): BaseQueryFn {
    return async (args, api, extraOptions) => {
        let result = await baseQuery(args, api, extraOptions)
        if (result.error && result.error.status === 401) {
            // get a new token
            const pca = new PublicClientApplication(msalConfig)
            const account = pca.getAllAccounts()[0]
            if (!account) {
                pca.loginRedirect(loginRequest).catch(e => console.log(e))
            }
            try {
                let response = await pca.acquireTokenSilent({...loginRequest, account});
                store.dispatch(set(response.accessToken))
                result = await baseQuery(args, api, extraOptions)
            } catch (e) {
                if (e instanceof InteractionRequiredAuthError) {
                    pca.acquireTokenRedirect({...loginRequest, account}).catch(e => console.log(e))
                }
            }
        }
        return result
    }
}