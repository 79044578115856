import {NavLink, Outlet} from "react-router-dom";
import {t} from "../../lang/lang";
import {useShowProductListQuery, useShowStoreListQuery} from "../../services/backend/api";
import {ChangeEvent, useState} from "react";
import UnconfirmedProductsAlert from "../../shared/unconfirmed-products-alert";
import {useActiveCompany} from "../../shared/active-company/active-company-hook";
import {Store} from "../../services/backend/store";
import {Product} from "../../services/backend/product";

export default function InventoriesRoute() {
    return (
        <div>
            <Search/>
            <div className="py-6">
                <UnconfirmedProductsAlert/>
                <Outlet/>
            </div>
        </div>
    )
}

function Search() {
    const {activeCompanyId} = useActiveCompany()
    const {data: stores, isSuccess} = useShowStoreListQuery({
        companyId: activeCompanyId ?? "",
    })
    const {data: products, isSuccess: productIsSuccess} = useShowProductListQuery({
        companyId: activeCompanyId ?? "",
        orderBy: "name asc"
    })

    const [search, setSearch] = useState("")

    const searchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
    }

    if (!isSuccess || !productIsSuccess) {
        return (<div></div>)
    }

    return (
        <div>
            <div>
                <label htmlFor="default-search"
                       className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none"
                             stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                        </svg>
                    </div>
                    <input type="search" id="default-search"
                           value={search}
                           onChange={searchTextChange}
                           className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                           placeholder={t('Lager oder Produkt suchen')} required/>
                </div>
            </div>
            <SearchResultList filter={search} stores={stores ?? []} products={products ?? []}
                              onNavigate={() => setSearch("")}/>
        </div>
    )
}

function SearchResultList(props: { filter: string, stores: Store[], products: Product[], onNavigate: () => void }) {
    let results: JSX.Element[] = []
    props.stores.forEach((store) => {
        if (props.filter == "") {
            return;
        }
        if (store.name.toLowerCase().indexOf(props.filter.toLowerCase()) === -1) {
            return
        }
        results.push(
            <li className="py-2 px-4 w-full" key={store.id}>
                <NavLink
                    to={`/inventories/stores/${store.id}/inventory`}
                    onClick={() => props.onNavigate()}
                >{store.name}</NavLink>
            </li>
        )
    })
    props.products.forEach((product) => {
        if (props.filter == "") {
            return;
        }
        if (product.name.toLowerCase().indexOf(props.filter.toLowerCase()) === -1) {
            return
        }
        results.push(
            <li className="py-2 px-4 w-full" key={product.id}>
                <NavLink
                    to={`/inventories/products/${product.id}/inventory`}
                    onClick={() => props.onNavigate()}
                >{product.name}</NavLink>
            </li>
        )
    })

    return (
        results.length > 0
            ? <div className="relative w-full z-10">
                <ul className="absolute w-full top-2 text-sm font-medium text-gray-900 bg-white rounded-lg divide-y border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                    {results}
                </ul>
            </div>
            : null
    )
}