import {ReactNode} from "react";
import {useAuth} from "./provider";
import {userHasRole} from "../../services/backend/user";
import {errorPageFor} from "../../routes/error-pages";

export function RoleGuard(props: { children: ReactNode, oneOfRoles: string[] }) {
    const {user} = useAuth()

    if (!user) {
        return <></>
    }

    for (const role of props.oneOfRoles) {
        if (userHasRole(user, role)) {
            return <>{props.children}</>
        }
    }
    return errorPageFor({status: 403})
}

export function RoleGuardHide(props: { children: ReactNode, oneOfRoles: string[] }) {
    const {user} = useAuth()

    if (!user) {
        return <></>
    }

    for (const role of props.oneOfRoles) {
        if (userHasRole(user, role)) {
            return <>{props.children}</>
        }
    }
    return <></>
}