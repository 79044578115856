import {TabNavigation, TabNavigationItem} from "../../shared/components/tab-navigation";
import {t} from "../../lang/lang";
import {Outlet} from "react-router-dom";
import {Heading2} from "../../shared/components/headings";
import Paragraph from "../../shared/components/paragraph";

export default function QrCodesRoute() {
    return (<>
        <Heading2>{t("QR-Codes")}</Heading2>
        <p className={"mt-3"}></p>
        <Paragraph>{t("Die Produkt- und Lagerdaten können als .csv-Datei heruntergeladen werden. Der QR-Code und die Gestaltung darum herum, kann dann mit diesen Daten erfolgen.")}</Paragraph>
        <Paragraph>{t("Wichtig ist, dass der QR-Code selbst lediglich die ID des Produktes (nicht die Produktnummer) oder des Lager enthalten soll und keine weiteren Informationen.")}</Paragraph>
        <p className={"mt-3"}></p>
        <TabNavigation>
            <TabNavigationItem text={t("Produkte")} to={'products'}/>
            <TabNavigationItem text={t("Läger")} to={'stores'}/>
        </TabNavigation>
        <div className="pt-3 sm:px-2">
            <Outlet/>
        </div>
    </>)
}