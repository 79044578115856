import {NavLink, Outlet} from "react-router-dom";
import {t} from "../lang/lang";
import {useAuth} from "../shared/authentication/provider";
import {ReactComponent as DHS} from "../assets/dhs_logo_leiste.svg";
import {ReactComponent as GESEC} from "../assets/gesec_logo_leiste.svg";
import {ReactComponent as GEMEX} from "../assets/gemex_logo_leiste.svg";
import {Menu} from "@headlessui/react";
import {ADMINISTRATOR, BRANCHMANAGER, DATAMANAGER, DIRECTOR, initials} from "../services/backend/user";
import Notifications from "./notifications";
import {CompanyIcon, CostCenterIcon, LogoutIcon, NotificationIcon} from "../shared/components/icons";
import {useActiveCompany} from "../shared/active-company/active-company-hook";
import {useShowCompanyQuery, useShowUserCompaniesQuery} from "../services/backend/api";
import {RoleGuardHide} from "../shared/authentication/role-guard";

export default function Root() {
    return (
        <div>
            <div className="fixed w-full z-20 bg-white">
                <Header/>
            </div>

            <div className="fixed w-52 z-10 pt-20 pl-2 bg-white h-screen hidden sm:block">
                <div className="pt-2">
                    <Navigation/>
                </div>
            </div>

            <div className="pt-20 pl-2 pb-6 sm:pl-52 pr-2">
                <div className="pt-2 sm:pt-4 sm:px-2">
                    <Outlet/>
                </div>
            </div>
        </div>
    );
}

function Header() {
    const {activeCompanyId} = useActiveCompany()
    return (
        <div className="bg-gray-100 mt-2 mx-2 py-4 px-2 sm:px-4 rounded">
            <div className="flex justify-between">
                <a href="/" className="flex items-center">
                    <div><LogoForCompany companyId={activeCompanyId ?? ""} className={"h-6 sm:h-9"}/></div>
                </a>
                <div className="flex gap-2">
                    <Notifications/>
                    <UserDropdown/>
                </div>
            </div>
        </div>
    )
}

function UserDropdown() {
    const {user, onLogout} = useAuth()
    const {data: companies, isSuccess: companiesIsSuccess} = useShowUserCompaniesQuery()
    const {resetActiveCompany} = useActiveCompany()

    return (
        <div>
            <Menu>
                {({open}) => (<div className={"relative"}>
                    <Menu.Button
                        className={open
                            ? "inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-gray-300 hover:bg-gray-300 rounded-full dark:bg-gray-100 dark:hover:bg-gray-100"
                            : "inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-gray-200 hover:bg-gray-300 rounded-full dark:bg-gray-600 dark:hover:bg-gray-100"}>
                        <span
                            className="font-medium text-gray-600 dark:text-gray-300">{initials(user?.name ?? "")}</span>
                    </Menu.Button>
                    <Menu.Items
                        className="z-10 absolute top-12 right-0 w-52 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700">
                        <div className="py-1 text-sm text-gray-700 dark:text-gray-200">
                            <Menu.Item>
                                <button
                                    type="button"
                                    onClick={() => onLogout()}
                                    className="block py-2 px-4 text-left w-full hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                    <div className="flex items-center space-x-3">
                                        <div className="flex-3"><LogoutIcon/></div>
                                        <span>Logout</span>
                                    </div>
                                </button>
                            </Menu.Item>
                            {
                                companiesIsSuccess && companies.length > 1
                                    ? <Menu.Item>
                                        <button
                                            type="button"
                                            onClick={() => resetActiveCompany()}
                                            className="block py-2 px-4 text-left w-full hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                            <div className="flex items-center space-x-3">
                                                <div className="flex-3"><CompanyIcon/></div>
                                                <span>{t("Unternehmen wechseln")}</span>
                                            </div>
                                        </button>
                                    </Menu.Item>
                                    : null
                            }
                            <Menu.Item>
                                <NavLink to={`settings/notifications`}>
                                    <div
                                        className="block py-2 px-4 text-left w-full hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                        <div className="flex items-center space-x-3">
                                            <div className="flex-3"><NotificationIcon/></div>
                                            <span>{t("Benachrichtigungen")}</span>
                                        </div>
                                    </div>
                                </NavLink>
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </div>)}
            </Menu>
        </div>
    )
}

function Navigation() {
    return (
        <nav className="w-48 overflow-y-auto py-4 px-3 bg-gray-100 rounded dark:bg-gray-800 h-screen">
            <ul className="space-y-2">
                <li>
                    <NavLink
                        to={`inventories`}
                        className={({isActive}) => isActive
                            ? "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white bg-gray-200 dark:bg-gray-700"
                            : "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
                        }>
                        <svg
                            className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                            fill="currentColor" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M39.8 41.95 26.65 28.8q-1.5 1.3-3.5 2.025-2 .725-4.25.725-5.4 0-9.15-3.75T6 18.75q0-5.3 3.75-9.05 3.75-3.75 9.1-3.75 5.3 0 9.025 3.75 3.725 3.75 3.725 9.05 0 2.15-.7 4.15-.7 2-2.1 3.75L42 39.75Zm-20.95-13.4q4.05 0 6.9-2.875Q28.6 22.8 28.6 18.75t-2.85-6.925Q22.9 8.95 18.85 8.95q-4.1 0-6.975 2.875T9 18.75q0 4.05 2.875 6.925t6.975 2.875Z"/>
                        </svg>
                        <span className="ml-3">{t('Bestand')}</span>
                    </NavLink>
                </li>
                <RoleGuardHide oneOfRoles={[ADMINISTRATOR, DATAMANAGER, DIRECTOR]}>
                    <li>
                        <NavLink
                            to={`products/list`}
                            className={({isActive}) => isActive
                                ? "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white bg-gray-200 dark:bg-gray-700"
                                : "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
                            }>
                            <svg
                                className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                                fill="currentColor" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="m13.05 21.7 11-17.7 11 17.7ZM35.3 44q-3.7 0-6.2-2.5t-2.5-6.2q0-3.7 2.5-6.2t6.2-2.5q3.7 0 6.2 2.5t2.5 6.2q0 3.7-2.5 6.2T35.3 44ZM6 42.75v-15.2h15.2v15.2ZM35.3 41q2.4 0 4.05-1.65T41 35.3q0-2.4-1.65-4.05T35.3 29.6q-2.4 0-4.05 1.65T29.6 35.3q0 2.4 1.65 4.05T35.3 41ZM9 39.75h9.2v-9.2H9Zm9.45-21.05h11.2l-5.6-9.05Zm5.6 0ZM18.2 30.55Zm17.1 4.75Z"/>
                            </svg>
                            <span className="ml-3">{t('Produkte')}</span>
                        </NavLink>
                    </li>
                </RoleGuardHide>
                <RoleGuardHide oneOfRoles={[ADMINISTRATOR, DATAMANAGER, DIRECTOR, BRANCHMANAGER]}>
                    <li>
                        <NavLink
                            to={`qr-codes/products`}
                            className={({isActive}) => isActive
                                ? "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white bg-gray-200 dark:bg-gray-700"
                                : "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
                            }>
                            <svg
                                className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                                fill="currentColor" stroke="none" viewBox="0 0 48 48"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M26 42v-4h4v4Zm-4-4V28h4v10Zm16-6v-8h4v8Zm-4-8v-4h4v4Zm-24 4v-4h4v4Zm-4-4v-4h4v4Zm18-14V6h4v4ZM8.5 15.5h7v-7h-7ZM6 18V6h12v12Zm2.5 21.5h7v-7h-7ZM6 42V30h12v12Zm26.5-26.5h7v-7h-7ZM30 18V6h12v12Zm4 24v-6h-4v-4h8v6h4v4Zm-8-14v-4h8v4Zm-8 0v-4h-4v-4h12v4h-4v4Zm2-10v-8h4v4h4v4Zm-9.5-4.5v-3h3v3Zm0 24v-3h3v3Zm24-24v-3h3v3Z"/>
                            </svg>
                            <span className="ml-3">{t('QR-Codes')}</span>
                        </NavLink>
                    </li>
                </RoleGuardHide>
                <RoleGuardHide oneOfRoles={[ADMINISTRATOR, DATAMANAGER, DIRECTOR, BRANCHMANAGER]}>
                    <li>
                        <NavLink
                            to={`reporting`}
                            className={({isActive}) => isActive
                                ? "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white bg-gray-200 dark:bg-gray-700"
                                : "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
                            }>
                            <svg
                                className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                                fill="currentColor" stroke="none" viewBox="0 0 48 48"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6 42v-3.8l3-3V42Zm8.25 0V30.2l3-3V42Zm8.25 0V27.2l3 3.05V42Zm8.25 0V30.25l3-3V42ZM39 42V22.2l3-3V42ZM6 30.2v-4.25l14-13.9 8 8L42 6v4.25L28 24.3l-8-8Z"/>
                            </svg>
                            <span className="ml-3">{t('Reporting')}</span>
                        </NavLink>
                    </li>
                </RoleGuardHide>
                <RoleGuardHide oneOfRoles={[ADMINISTRATOR, DATAMANAGER, DIRECTOR]}>
                    <li>
                        <NavLink
                            to={`stores`}
                            className={({isActive}) => isActive
                                ? "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white bg-gray-200 dark:bg-gray-700"
                                : "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
                            }>
                            <svg
                                className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                                fill="currentColor" stroke="none" viewBox="0 0 48 48"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 42V14l20-8 20 8v28H32V26H16v16Zm14.5 0v-3h3v3Zm4-6v-3h3v3Zm4 6v-3h3v3Z"/>
                            </svg>
                            <span className="ml-3">{t('Läger')}</span>
                        </NavLink>
                    </li>
                </RoleGuardHide>
                <RoleGuardHide oneOfRoles={[ADMINISTRATOR, DATAMANAGER, DIRECTOR]}>
                    <li>
                        <NavLink
                            to={`cost-centers`}
                            className={({isActive}) => isActive
                                ? "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white bg-gray-200 dark:bg-gray-700"
                                : "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
                            }>
                            <div className="flex-none"><CostCenterIcon/></div>
                            <span className="ml-3">{t('Kostenstellen')}</span>
                        </NavLink>
                    </li>
                </RoleGuardHide>
                <RoleGuardHide oneOfRoles={[ADMINISTRATOR]}>
                    <li>
                        <NavLink
                            to={`companies`}
                            className={({isActive}) => isActive
                                ? "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white bg-gray-200 dark:bg-gray-700"
                                : "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
                            }>
                            <svg
                                className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                                fill="currentColor" stroke="none" viewBox="0 0 48 48"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6 42V14.25h8.25V6h19.5v16.5H42V42H26.65v-8.25h-5.3V42Zm3-3h5.25v-5.25H9Zm0-8.25h5.25V25.5H9Zm0-8.25h5.25v-5.25H9Zm8.25 8.25h5.25V25.5h-5.25Zm0-8.25h5.25v-5.25h-5.25Zm0-8.25h5.25V9h-5.25Zm8.25 16.5h5.25V25.5H25.5Zm0-8.25h5.25v-5.25H25.5Zm0-8.25h5.25V9H25.5ZM33.75 39H39v-5.25h-5.25Zm0-8.25H39V25.5h-5.25Z"/>
                            </svg>
                            <span className="ml-3">{t('Unternehmen')}</span>
                        </NavLink>
                    </li>
                </RoleGuardHide>
            </ul>
        </nav>
    )
}

export function LogoForCompany(props: { companyId: string, className: string }) {
    const {data: company, isSuccess} = useShowCompanyQuery({
        companyId: props.companyId
    })

    if (!isSuccess) {
        return <></>
    }

    switch (company.name) {
        case "DHS":
            return <DHS className={props.className}/>
        case "GESEC":
            return <GESEC className={props.className}/>
        case "GEMEX":
            return <GEMEX className={props.className}/>
        default:
            return <div>{company.name}</div>
    }
}