import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store";
import {reset, set} from "./active-company-slice";

export function useActiveCompany() {
    const activeCompanyId = useSelector((state: RootState) => state.activeCompany.value)
    const dispatch = useDispatch<AppDispatch>()

    const setActiveCompany = (companyId: string) => {
        dispatch(set(companyId))
    }

    const resetActiveCompany = () => {
        dispatch(reset())
    }

    return {activeCompanyId, setActiveCompany, resetActiveCompany}
}
