import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from './store';
import reportWebVitals from './reportWebVitals';
import './index.css';
import {router} from "./routes/router";
import {RouterProvider} from "react-router-dom";
import {MsalProvider} from "@azure/msal-react";
import {PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./shared/authentication/msal";

const container = document.getElementById('root')!;
const root = createRoot(container);
const msalInstance = new PublicClientApplication(msalConfig);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <MsalProvider instance={msalInstance}>
                <RouterProvider router={router}/>
            </MsalProvider>
        </Provider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
