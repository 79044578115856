import {useMsal} from "@azure/msal-react";
import {AuthContext, AuthContextInterface} from "./provider";
import {PropsWithChildren} from "react";
import {User} from "../../services/backend/user";
import {errorPageFor} from "../../routes/error-pages";

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_OIDC_APPLICATION_ID ?? "",
        authority: process.env.REACT_APP_OIDC_AUTHORITY,
        redirectUri: process.env.REACT_APP_OIDC_REDIRECT_URI,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: [
        process.env.REACT_APP_OIDC_BACKEND_SCOPE ?? "",
    ],
};

export function AuthContextProviderMsal(props: PropsWithChildren) {
    const {accounts, instance} = useMsal()

    if (accounts[0]) {
        const idToken = accounts[0].idTokenClaims!
        const user: User = {
            id: idToken.oid!,
            name: idToken.name!,
            roles: idToken.roles!,
        }
        const onLogout = () => {
            const homeAccountId = user.id + "." + process.env.REACT_APP_AZURE_TENANT_ID
            const logoutRequest = {
                account: instance.getAccountByHomeId(homeAccountId),
                postLogoutRedirectUrl: "/",
            }
            instance.logoutRedirect(logoutRequest).then()
        }
        const value = {user, onLogout} as AuthContextInterface
        return (
            <AuthContext.Provider value={value}>
                {props.children}
            </AuthContext.Provider>
        )
    } else {
        return errorPageFor({status: 403})
    }
}
