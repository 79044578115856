import {
    useConfirmProductMutation,
    useReplaceProductMutation,
    useShowProductListQuery,
    useUpdateProductMutation
} from "../../services/backend/api";
import {t} from "../../lang/lang";
import {ChangeEvent, useState} from "react";
import {errorMessageIn, validationErrorsFor} from "../../services/backend/errors";
import {Button} from "../../shared/components/button";
import {DangerAlert, InfoAlert} from "../../shared/components/alerts";
import {FormSelectInput, FormTextInput} from "../../shared/components/form";
import {useActiveCompany} from "../../shared/active-company/active-company-hook";
import {measures, Product} from "../../services/backend/product";
import Paragraph from "../../shared/components/paragraph";

export default function RequestedProducts() {
    const {activeCompanyId} = useActiveCompany()

    const {data: products, isSuccess} = useShowProductListQuery({
        companyId: activeCompanyId ?? "",
        orderBy: "name asc"
    }, {
        refetchOnMountOrArgChange: true,
    })
    if (!isSuccess) {
        return <></>
    }

    const unconfirmedProducts = products.filter((product) => product.confirmed == false)
    if (unconfirmedProducts.length == 0) {
        return <p
            className="font-light text-gray-500 dark:text-gray-400">{t("Keine unbestätigten Produkte vorhanden.")}</p>
    }

    return (<>
        {
            unconfirmedProducts.map((product) => <div key={product.id}><RequestedProduct product={product}/></div>)
        }
    </>)
}

function RequestedProduct(props: { product: Product }) {
    return (<>
        <div
            className="block px-4 pt-3 pb-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
            <UpdateProduct product={props.product}/>
            <hr className="my-4 h-px bg-gray-200 border-0 dark:bg-gray-700"/>
            <ReplaceProduct product={props.product}/>
        </div>
    </>)
}

function UpdateProduct(props: { product: Product }) {
    const [name, setName] = useState(props.product.name)
    const [nameErrors, setNameErrors] = useState<string[]>([])
    const [productNumber, setProductNumber] = useState(props.product.productNumber)
    const [productNumberErrors, setProductNumberErrors] = useState<string[]>([])
    const [manufacturerName, setManufacturerName] = useState(props.product.manufacturerName)
    const [manufacturerNameErrors, setManufacturerNameErrors] = useState<string[]>([])
    const [measure, setMeasure] = useState(props.product.measure)
    const [measureErrors, setMeasureErrors] = useState<string[]>([])
    const [generalError, setGeneralError] = useState<string>("")
    const [confirmationError, setConfirmationError] = useState<string>("")
    const [update, {isLoading: updateIsLoading}] = useUpdateProductMutation()
    const [confirm, {isLoading: confirmIsLoading}] = useConfirmProductMutation()

    const inputChanged = name != props.product.name ||
        productNumber != props.product.productNumber ||
        manufacturerName != props.product.manufacturerName ||
        measure != props.product.measure
    const canUpdate = inputChanged && !updateIsLoading
    const canConfirm = !confirmIsLoading

    const onNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
        setNameErrors([])
    }

    const onProductNumberChanged = (e: ChangeEvent<HTMLInputElement>) => {
        setProductNumber(e.target.value)
        setProductNumberErrors([])
    }

    const onManufacturerNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
        setManufacturerName(e.target.value)
        setManufacturerNameErrors([])
    }

    const onMeasureChanged = (e: ChangeEvent<HTMLSelectElement>) => {
        setMeasure(e.target.value)
        setMeasureErrors([])
    }

    const onUpdateClick = () => {
        if (canUpdate) {
            setGeneralError("")
            update({
                productId: props.product.id,
                companyId: props.product.companyId,
                name: name,
                productNumber: productNumber,
                manufacturerName: manufacturerName,
                measure: measure,
            }).unwrap().catch(err => {
                setNameErrors(validationErrorsFor("name", err))
                setProductNumberErrors(validationErrorsFor("productNumber", err))
                setManufacturerNameErrors(validationErrorsFor("manufacturerName", err))
                setMeasureErrors(validationErrorsFor("measure", err))
                setGeneralError(errorMessageIn(err))
            })
        }
    }

    const onConfirmClick = () => {
        if (canConfirm) {
            setConfirmationError("")
            confirm({
                productId: props.product.id,
            }).unwrap().catch(err => {
                setConfirmationError(errorMessageIn(err))
            })
        }
    }

    return (<>
        <h5 className="mb-2 font-bold text-gray-900 dark:text-white">Angefordertes Produkt</h5>
        <div className="mb-3">
            <Paragraph>
                {t("Hier können Sie ein angefordertes Produkt bestätigen und die Daten aktualisieren falls diese falsch oder unvollständig angefordert wurden. Die Daten sind im Anschluss nicht mehr änderbar. Bitte überprüfen Sie deshalb gewissenhaft die Eingaben.")}
            </Paragraph>
        </div>
        <DangerAlert>{generalError}</DangerAlert>
        <DangerAlert>{confirmationError}</DangerAlert>
        <div className="sm:columns-2">
            <div className="mb-4">
                <FormTextInput value={name} label={t("Name")} placeholder={t("Name")} onChange={onNameChanged}
                               errors={nameErrors}/>
            </div>
            <div className="mb-4">
                <FormTextInput value={productNumber} label={t("Produkt Nummer")}
                               placeholder={t("Produkt Nummer (Optional)")} onChange={onProductNumberChanged}
                               errors={productNumberErrors}/>
            </div>
            <div className="mb-4">
                <FormTextInput value={manufacturerName} label={t("Hersteller")} placeholder={t("Hersteller (Optional)")}
                               onChange={onManufacturerNameChanged} errors={manufacturerNameErrors}/>
            </div>
            <div className="mb-4">
                <FormSelectInput value={measure} options={measures.map((m) => ({value: m, display: m}))}
                                 label={t("Maßeinheit")} onChange={onMeasureChanged} errors={measureErrors}
                                 disabled={true}/>
            </div>
        </div>
        <InfoAlert>{t("Die Maßeinheit ist nicht änderbar. Eventuell bereits erfasste Bestände könnten sonst verfälscht werden.")}</InfoAlert>
        <div className="text-right">
            {
                inputChanged
                    ? <Button text={t("Aktualisieren")} onClick={onUpdateClick}/>
                    : <Button text={t("Bestätigen")} onClick={onConfirmClick}/>
            }
        </div>
    </>)
}

function ReplaceProduct(props: { product: Product }) {
    const [replacement, setReplacement] = useState<string>("")
    const [replacementError, setReplacementError] = useState<string>("")
    const [replace, {isLoading: replaceIsLoading}] = useReplaceProductMutation()
    const {activeCompanyId} = useActiveCompany()
    const {data: products, isSuccess} = useShowProductListQuery({companyId: activeCompanyId ?? "", orderBy: "name asc"})
    if (!isSuccess) {
        return <></>
    }
    const selectableProducts = products.filter((product) => product.measure == props.product.measure)
    const onReplacementChanged = (e: ChangeEvent<HTMLSelectElement>) => {
        setReplacement(e.target.value)
    }
    const canReplace = replacement != "" && !replaceIsLoading
    const onReplaceClick = () => {
        if (canReplace) {
            replace({
                productId: props.product.id,
                replacementId: replacement,
            }).unwrap().catch(err => {
                setReplacementError(errorMessageIn(err))
            })
        }
    }
    return (<>
        <h5 className="mb-2 font-bold text-gray-900 dark:text-white">{t("Mit bestehendem Produkt ersetzen")}</h5>
        <div className="mb-3">
            <Paragraph>
                {t("Hier können Sie ein angefordertes Produkt durch ein Produkt ersetzen, das bereits registriert ist. Das ist hilfreich, sollte versehentlich ein Produkt angefordert worden sein, das bereits registriert ist.")}
            </Paragraph>
        </div>
        <DangerAlert>{replacementError}</DangerAlert>
        <FormSelectInput
            value={replacement}
            options={selectableProducts.map((p) => ({value: p.id, display: p.name + ", " + t(p.measure)})) ?? []}
            onChange={onReplacementChanged}
            errors={[]}/>
        <div className="text-right mt-4">
            <InfoAlert>{t("Man kann nur Produkte mit der gleichen Maßeinheit zum ersetzen auswählen.")}</InfoAlert>
            <Button text={t("Ersetzen")} onClick={onReplaceClick} disabled={!canReplace}/>
        </div>
    </>)
}
