import {useParams} from "react-router-dom";
import {useShowProductInventoryQuery} from "../../../services/backend/api";
import {ChangeEvent, useState} from "react";
import {t} from "../../../lang/lang";
import {ProductInventoryItem} from "../../../services/backend/ledger";

export default function ProductInventoryRoute() {
    const {productId} = useParams()
    const {data: productInventory, isSuccess} = useShowProductInventoryQuery({productId: productId ?? ""})
    const [filter, setFilter] = useState("")
    if (!isSuccess) {
        return <></>
    }

    let filteredItems: ProductInventoryItem[] = productInventory.inventory.filter((item: ProductInventoryItem) => {
        return filter == "" || item.storeName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    })

    let rows: JSX.Element[] = []
    filteredItems.forEach((item, index) => {
        rows.push(
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                key={index}>
                <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {item.storeName}
                </th>
                <td className="py-4 px-6 text-right">
                    {item.count}
                </td>
                <td className="py-4 px-6 text-right">
                    {t(item.measure)}
                </td>
            </tr>
        )
    })

    return (<>
        <div className="pb-4 bg-white dark:bg-gray-900">
            <label htmlFor="table-search" className="sr-only">Search</label>
            <div className="relative mt-1">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor"
                         viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clipRule="evenodd"></path>
                    </svg>
                </div>
                <input type="text" id="table-search"
                       value={filter}
                       onChange={(e: ChangeEvent<HTMLInputElement>) => setFilter(e.target.value)}
                       className="block p-2 pl-10 w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                       placeholder={t("Tabelle durchsuchen")}/>
            </div>
        </div>

        <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="py-3 px-6">
                        {t("Lager")}
                    </th>
                    <th scope="col" className="py-3 px-6">
                        {t("Anzahl")}
                    </th>
                    <th scope="col" className="py-3 px-6">
                        {t("Einheit")}
                    </th>
                </tr>
                </thead>
                <tbody>
                {productInventory.inventory.length > 0
                    ? rows.length > 0
                        ? rows
                        : <tr>
                            <td colSpan={3} className="py-3 px-6 text-center">{t("Produkt nicht gefunden")}</td>
                        </tr>
                    : <tr>
                        <td colSpan={3} className="py-3 px-6 text-center">{t("Das Lager ist leer")}</td>
                    </tr>}
                </tbody>
                <tfoot>
                {rows.length > 0
                    ? <tr className="font-semibold text-gray-900 dark:text-white">
                        <th scope="row" className="py-3 px-6 text-base">Total</th>
                        <td className="py-3 px-6 text-right">{filteredItems.reduce((carry, item) => carry + item.count, 0)}</td>
                        <td className="py-3 px-6 text-right">{t(filteredItems[0].measure)}</td>
                    </tr>
                    : null
                }
                </tfoot>
            </table>
        </div>
    </>)
}