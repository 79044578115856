import {PropsWithChildren} from "react";

export function Heading1(props: PropsWithChildren) {
    return <h1 className="text-5xl font-extrabold dark:text-white">{props.children}</h1>
}

export function Heading2(props: PropsWithChildren) {
    return <h2 className="text-4xl font-bold dark:text-white">{props.children}</h2>
}

export function Heading3(props: PropsWithChildren) {
    return <h3 className="text-3xl font-bold dark:text-white">{props.children}</h3>
}

export function Heading4(props: PropsWithChildren) {
    return <h4 className="text-2xl font-bold dark:text-white">{props.children}</h4>
}

export function Heading5(props: PropsWithChildren) {
    return <h5 className="text-xl font-bold dark:text-white">{props.children}</h5>
}

export function Heading6(props: PropsWithChildren) {
    return <h6 className="text-lg font-bold dark:text-white">{props.children}</h6>
}