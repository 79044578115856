export interface Product {
    id: string
    productNumber: string
    companyId: string
    name: string
    manufacturerName: string
    measure: string
    confirmed: boolean
    active: boolean
}

export const measures: string[] = [
    "pieces",
    "meter",
    "milligram",
    "gram",
    "kilogram",
    "milliliter",
    "liter",
    "roll",
    "pair",
    "bottle",
    "canister",
]

export interface ShowProductListRequest {
    companyId?: string
    confirmed?: boolean
    active?: boolean
    limit?: number
    orderBy?: string
}

export interface ShowProductRequest {
    productId: string
}

export interface NewProductRequest {
    id: string
    productNumber: string
    companyId: string
    name: string
    manufacturerName: string
    measure: string
}

export interface UpdateUnconfirmedProductRequest {
    productId: string
    companyId: string
    productNumber?: string
    name?: string
    manufacturerName?: string
    measure?: string
}

export interface ReplaceProductRequest {
    productId: string
    replacementId: string
}

export interface ConfirmProductRequest {
    productId: string
}

export interface ActivateProductRequest {
    productId: string
}

export interface DeactivateProductRequest {
    productId: string
}