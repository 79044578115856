import {TabNavigation, TabNavigationItem} from "../../shared/components/tab-navigation";
import {t} from "../../lang/lang";
import {Outlet} from "react-router-dom";

export default function ProductsRoute() {
    return (<>
        <TabNavigation>
            <TabNavigationItem text={t("Produkte")} to={`list`}/>
            <TabNavigationItem text={t("Angeforderte Produkte")} to={`requested`}/>
        </TabNavigation>
        <div className="pt-3 sm:px-2">
            <Outlet/>
        </div>
    </>)
}