import {Menu} from "@headlessui/react";
import {useAuth} from "../shared/authentication/provider";
import {useAcknowledgePortalNotificationMutation, useShowPortalNotificationsQuery} from "../services/backend/api";
import {t, tTopic} from "../lang/lang";
import {Link} from "react-router-dom";

export default function Notifications() {
    const {user} = useAuth()

    const {data: notifications, isSuccess} = useShowPortalNotificationsQuery({
        userId: user?.id ?? "",
    }, {
        pollingInterval: +(process.env.REACT_APP_NOTIFICATION_POLLING_INTERVAL ?? 0),
    })
    const [acknowledge] = useAcknowledgePortalNotificationMutation()

    if (!isSuccess) {
        return <></>
    }

    const acknowledgeAll = () => {
        if (isSuccess) {
            notifications.forEach((notification) => acknowledge({notificationId: notification.id}))
        }
    }

    if (notifications.length == 0) {
        return (
            <Menu>
                {({open}) => (
                    <div className={"relative"}>
                        <Menu.Button className={"relative"}>
                            <div className={open
                                ? "inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-gray-300 hover:bg-gray-300 rounded-full dark:bg-gray-100 dark:hover:bg-gray-100"
                                : "inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-gray-200 hover:bg-gray-300 rounded-full dark:bg-gray-600 dark:hover:bg-gray-100"}>
                                <svg className="w-6 h-6 font-medium text-gray-600 dark:text-gray-300" fill="none"
                                     stroke="currentColor" viewBox="0 0 24 24"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>
                                </svg>
                            </div>
                        </Menu.Button>
                        <Menu.Items
                            className="z-10 absolute top-12 right-0 w-52 bg-white rounded shadow dark:bg-gray-700">
                            <Menu.Item>
                                <div className="p-4 text-sm text-right">{t("Keine Benachrichtigungen")}</div>
                            </Menu.Item>
                        </Menu.Items>
                    </div>
                )}
            </Menu>
        )
    }

    return (<div>
        <Menu>
            {({open}) => (
                <div className={"relative"}>
                    <Menu.Button className={"relative"}>
                        <div className={open
                            ? "inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-gray-300 hover:bg-gray-300 rounded-full dark:bg-gray-100 dark:hover:bg-gray-100"
                            : "inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-gray-200 hover:bg-gray-300 rounded-full dark:bg-gray-600 dark:hover:bg-gray-100"}>
                            <svg className="w-6 h-6 font-medium text-gray-600 dark:text-gray-300" fill="none"
                                 stroke="currentColor" viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>
                            </svg>
                        </div>
                        <div
                            className="inline-flex absolute -top-2 -right-2 justify-center items-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900">
                            {notifications.length}
                        </div>
                    </Menu.Button>
                    <Menu.Items className="z-10 absolute top-12 right-0 w-72 bg-white rounded shadow dark:bg-gray-700">
                        <Menu.Item>
                            <div className="flex flex-row-reverse px-2 pt-2">
                                <button type="button"
                                        onClick={() => acknowledgeAll()}
                                        className="py-2 px-3 text-xs font-medium text-center text-blue-700 rounded-lg hover:bg-blue-100 focus:ring-4 focus:outline-none focus:ring-blue-100 dark:hover:bg-blue-700 dark:focus:ring-blue-700">
                                    {t("Alle Entfernen")}
                                </button>
                            </div>
                        </Menu.Item>
                        {
                            notifications.map((notification) => (
                                <Menu.Item key={notification.id}>
                                    <Link to={`/products/requested`}
                                          onClick={() => acknowledge({notificationId: notification.id})}>
                                        <div
                                            className="px-4 py-3 m-2 bg-blue-100 hover:bg-blue-200 rounded-lg dark:bg-blue-200">
                                            <div className="flex items-center">
                                                <h3 className="text-sm font-medium text-blue-900">{tTopic(notification.topic)}</h3>
                                                <button type="button"
                                                        onClick={() => acknowledge({notificationId: notification.id})}
                                                        className="ml-auto -mx-1.5 -my-1.5 bg-blue-100 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-300 inline-flex h-6 w-6 dark:bg-blue-200 dark:text-blue-600 dark:hover:bg-blue-300">
                                                    <span className="sr-only">Close</span>
                                                    <svg aria-hidden="true" className="w-3 h-3" fill="currentColor"
                                                         viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd"
                                                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                              clipRule="evenodd"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                            <div className="text-xs text-blue-900">
                                                {t(`Angefordert am: ${new Date(notification.createdAt).toLocaleString()}`)}
                                            </div>
                                        </div>
                                    </Link>
                                </Menu.Item>
                            ))
                        }
                    </Menu.Items>
                </div>
            )}

        </Menu>
    </div>)
}